import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Organizations from './dig.organizations.component';

export const OrganizationsParcel = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Organizations,
  errorBoundary(err, info, props) {
    return (
      <div class="error-boundary">
        InReachVentures Organizations Parcel crashed. Please contact support.
      </div>
    );
  }
});
