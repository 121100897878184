import { camelCase } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

import { decisions } from "../../../../../const";
import users from "../../../../../components/js/config/inreach_team";

import "../../scss/StateUser.scoped.scss";

function StateUser(props) {
  const { filter, label, organizationsState } = props;

  const activeUsers = users.filter((user) => !user.exclude);

  return (
    <div class="rounded border p-1" style={{ background: "#fff" }}>
      <div>
        {Object.entries(decisions).map(([type, typeLabel]) => {
          return (
            <div class="form-check form-check-inline p-0">
              <input
                class="form-check-input m-1"
                type="radio"
                id={"decision-" + type}
                checked={
                  organizationsState[camelCase(filter + "_value")] === type
                }
                onChange={(event) => {
                  organizationsState.setOrganizations(null);
                  organizationsState.setOffset(0);
                  organizationsState[camelCase("set_" + filter + "_value")](
                    type
                  );
                }}
              />
              <label class="form-check-label" for={"decision-" + type}>
                {typeLabel}
              </label>
            </div>
          );
        })}
      </div>
      <div class="input-group input-group-sm">
        <label for="continentSelect" class="input-group-text">
          User
        </label>
        <select
          class="form-select form-select-sm"
          id="userSelect"
          onChange={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState.setStateUserUser(event.target.value);
          }}
        >
          <option selected={organizationsState.stateUserUser === ""} value="">
            All
          </option>
          {activeUsers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((user) => (
              <option
                selected={organizationsState.stateUserUser === user.email}
                value={user.email}
              >
                {user.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

export default observer(StateUser);
