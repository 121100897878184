import { camelCase } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

import { decisions } from "../../../../../const";

import "../../scss/StatePartner.scoped.scss";

function StatePartner(props) {
  const { filter, label, organizationsState } = props;

  return (
    <div>
      <div class="rounded border p-1" style={{ background: "#fff" }}>
        {Object.entries(decisions).map(([type, typeLabel]) => {
          return (
            <div class="form-check form-check-inline p-0">
              <input
                class="form-check-input m-1"
                type="radio"
                id={"decision-" + type}
                checked={
                  organizationsState[camelCase(filter + "_value")] === type
                }
                onChange={(event) => {
                  organizationsState.setOrganizations(null);
                  organizationsState.setOffset(0);
                  organizationsState[camelCase("set_" + filter + "_value")](
                    type
                  );
                }}
              />
              <label class="form-check-label" for={"decision-" + type}>
                {typeLabel}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default observer(StatePartner);
