import dayjs from "dayjs";

export const MAX_DATE = 32503726872000;

export const investmentTypesFilter = {
  saas: "SaaS",
  marketplace: "Marketplace",
  consumer: "Consumer",
  other: "Other",
};

export const filters = {
  separator_1: "General Info",
  createdAt: "Created At",
  foundedOn: "Founded On",
  investmentType: "Investment Type",
  location: "Location",
  separator_2: "Funding",
  investmentAmount: "Investment Amount",
  separator: "Product",
  keywords: "Keywords",
  separator_3: "People",
  daysTopTech: "Worked at Top Tech",
  previousExits: "Previous Exits",
  separator_4: "Machine Learning",
  classification: "ML",
  classificationNot: "ML Not",
  trafficClassification: "Traffic",
  organizationsSimilarTo: "Organizations Similar To",
  separator_5: "Process",
  assignedTo: "Assigned To",
  enteredStage: "Entered Stage",
  hasMissingData: "Has Missing Data",
  hasPredictions: "Has Predictions",
  isClosed: "Is Closed",
  isComplete: "Is Complete",
  isInInbox: "Is In Inbox",
  //isSpam: "Is Spam",
  isZombie: "Is Zombie",
  makeContactRequiresAction: "Make Contact Requires Action",
  statePartner: "Partner Decisions",
  requiresManualHelp: "Requires Manual Help",
  requiresMoreInfo: "Requires More Info",
  source: "Source",
  stage: "Stage",
  stateUser: "User Decisions",
  unableToContactRequiresAction: "Unable To Contact Requires Action",
};

export const filtersConfiguration = {
  createdAt: [
    { type: "date", param: "created_at_after", state: "createdAtG_T" },
    { type: "date", param: "created_at_before", state: "createdAtL_T" },
  ],
  foundedOn: [
    { type: "date", param: "founded_on_after", state: "foundedOnG_T" },
    { type: "date", param: "founded_on_before", state: "foundedOnL_T" },
  ],
  investmentType: [
    { type: "array", param: "investment_types", state: "investmentTypes" },
  ],
  location: [
    { type: "string", param: "based_in_continent", state: "locationContinent" },
    { type: "string", param: "based_in_country", state: "locationCountry" },
    { type: "string", param: "based_in_city", state: "locationCity" },
  ],
  investmentAmount: [
    { type: "integer", param: "investment_lt", state: "investmentAmountL_T" },
    { type: "integer", param: "investment_gt", state: "investmentAmountG_T" },
    {
      type: "integer",
      param: "last_funding_in_days_ago_lt",
      state: "investmentAmountDaysL_T",
    },
    {
      type: "integer",
      param: "last_funding_in_days_ago_gt",
      state: "investmentAmountDaysG_T",
    },
  ],
  keywords: [
    { type: "array", param: "terms", state: "terms" },
    { type: "logic", param: "terms_operator", state: "termsOperator" },
  ],
  traffic: [
    {
      type: "float",
      param: "primary_signal_total_area_gt",
      state: "trafficMagnitudeG_T",
    },
    {
      type: "float",
      param: "month_on_month_primary_signal_growth_full_gt",
      state: "trafficBetaG_T",
    },
  ],
  trafficClassification: [
    {
      type: "array",
      param: "traffic_classification",
      state: "trafficClassifications",
    },
  ],
  daysTopTech: [
    {
      type: "integer",
      param: "aggregated_days_at_top_tech_gt",
      state: "daysTopTechValue",
    },
  ],
  previousExits: [
    {
      type: "integer",
      param: "aggregated_people_with_previous_exits_gt",
      state: "previousExitsValue",
    },
  ],
  classification: [
    { type: "array", param: "classification", state: "classifications" },
  ],
  classificationNot: [
    { type: "array", param: "classification_not", state: "classificationsNot" },
  ],
  organizationsSimilarTo: [
    {
      type: "string",
      param: "organizations_similar_to",
      state: "organizationsSimilarToValue",
    },
  ],
  assignedTo: [
    { type: "string", param: "assigned_to", state: "assignedToValue" },
  ],
  enteredStage: [
    { type: "date", param: "decision_made_gt", state: "enteredStageG_T" },
    { type: "date", param: "decision_made_lt", state: "enteredStageL_T" },
  ],
  hasMissingData: [
    {
      type: "boolean",
      param: "has_missing_data",
      state: "hasMissingDataValue",
    },
  ],
  hasPredictions: [
    { type: "boolean", param: "has_predictions", state: "hasPredictionsValue" },
  ],
  isClosed: [{ type: "boolean", param: "is_closed", state: "isClosedValue" }],
  isComplete: [
    { type: "boolean", param: "is_complete", state: "isCompleteValue" },
  ],
  isInInbox: [
    { type: "boolean", param: "is_in_inbox", state: "isInInboxValue" },
  ],
  isZombie: [{ type: "boolean", param: "is_zombie", state: "isZombieValue" }],
  makeContactRequiresAction: [
    {
      type: "boolean",
      param: "make_contact_requires_action",
      state: "makeContactRequiresActionValues",
    },
  ],
  statePartner: [
    { type: "string", param: "state", state: "statePartnerValue" },
  ],
  requiresManualHelp: [
    {
      type: "boolean",
      param: "requires_manual_help",
      state: "requiresManualHelpValue",
    },
  ],
  requiresMoreInfo: [
    {
      type: "boolean",
      param: "requires_more_info",
      state: "requiresMoreInfoValue",
    },
  ],
  stage: [{ type: "string", param: "stage", state: "stageValue" }],
  source: [{ type: "string", param: "source", state: "sourceValue" }],
  stateUser: [
    { type: "string", param: "user_state", state: "stateUserValue" },
    { type: "string", param: "by_user", state: "stateUserUser" },
  ],
  unableToContactRequiresAction: [
    { type: "boolean", param: "unable_to_contact_requires_action" },
  ],
};
export const classifications = {
  contact: "In Progress",
  missed_opportunity: "Missed Opportunity",
  never: "Never",
  not_now: "Not Now",
};
export const trafficClassifications = {
  unknown: "Unknown",
  no_live_product: "No Live Product",
  decreasing: "decreasing",
  flat: "Flat",
  increasing: "Increasing",
};

export const decisions = {
  contact: "In Progress",
  missed_opportunity: "Missed Opportunity",
  never: "Never",
  not_now: "Not now",
  unseen: "Unseen",
};

export const investmentTypesGreen = ["saas", "marketplace", "consumer"];

export const orderFactors = {
  created_at: "Created At",
  "decisions.created_at": "Decision Created At",
  "decisions.updated_at": "Decision Updated At",
  founded_on: "Founded On",
  aggregated_followon_percentage: "Investor Score",
  score: "Match",
  explainable_scorer: "Explainable AI Score",
  ml_features_score: "ML Discovery Score",
  month_on_month_primary_signal_growth_full: "Traffic Traction",
  primary_signal_total_area: "Traffic Size",
  updated_at: "Updated At",
};

export const filtersFallback = {
  "/organizations": {
    //based_in_continent=Europe&classification_not=never%2Cmissed_opportunity&has_predictions=true&offset=0&requires_more_info=false
    based_in_continent: "Europe",
    classification_not: "never",
    has_predictions: "true",
    requires_more_info: "false",
    order: "desc",
    order_by: "founded_on",
  },
  "/discover": {
    //based_in_continent=Europe&classification_not=never%2Cmissed_opportunity&experiments_not=automated_outbound_send_form&has_missing_data=false&has_predictions=true&is_closed=false&is_zombie=false&offset=0&order_by=ml_features_score&state=unseen&user_state_not=never
    based_in_continent: "Europe",
    classification_not: "never,missed_opportunity",
    has_predictions: "true",
    has_missing_data: "false",
    is_zombie: "false",
    requires_more_info: "false",
    state: "unseen",
    order: "desc",
    order_by: "ml_features_score",
  },
  //based_in_continent=Europe&classification=never&is_in_inbox=false&offset=0&requires_more_info=false&state=unseen
  "/spam": {
    based_in_continent: "Europe",
    classification: "never",
    is_in_inbox: "false",
    requires_more_info: "false",
    state: "unseen",
    order: "desc",
    order_by: "founded_on",
  },
};

export const investmentAmountDaysZones = [
  { gt: -1, lt: MAX_DATE, label: "Always" },
  { gt: 0, lt: 180, label: "Last 6M" },
  { gt: 0, lt: 360, label: "Last 12M" },
  { gt: 0, lt: 540, label: "Last 18M" },
  { gt: 0, lt: 740, label: "Last 24M" },
  { gt: 180, lt: 360, label: "From 6M to 12M ago" },
  { gt: 360, lt: 540, label: "From 12M to 18M ago" },
];

export const organizationsStateDefaults = {
  gridView: false,
  offset: 0,
  total: 0,
  size: 10,
  mode: "assisted",
  organizations: null,
  scores: null,
  name: "",
  nameFlag: true,
  orderedBy: "founded_on",
  order: "desc",
  foundedOn: false,
  foundedOnGT: dayjs().startOf("month").subtract(18, "months"),
  foundedOnLT: dayjs().startOf("month").add(1, "months"),
  keywords: false,
  terms: new Set([]),
  termsOperator: "or",
  investmentType: false,
  investmentTypes: new Set([]),
  investmentAmount: false,
  investmentAmountGT: 0,
  investmentAmountLT: 700000,
  investmentAmountDaysGT: -1,
  investmentAmountDaysLT: MAX_DATE,
  traffic: false,
  trafficBetaGT: 1.5,
  trafficMagnitudeGT: 0,
  classification: false,
  classifications: new Set([]),
  classificationNot: true,
  classificationsNot: new Set([]),
  trafficClassification: false,
  trafficClassifications: new Set([]),
  currentWidget: "",
  apiStatus: 200,
  special: false,
  hasPredictions: true,
  hasPredictionsValue: true,
  hasMissingData: false,
  hasMissingDataValue: true,
  isClosed: false,
  isClosedValue: true,
  isComplete: false,
  isCompleteValue: true,
  isSpam: false,
  isSpamValue: true,
  isInInbox: false,
  isInInboxValue: true,
  isZombie: false,
  isZombieValue: true,
  requiresMoreInfo: true,
  requiresMoreInfoValue: false,
  requiresManualHelp: false,
  requiresManualHelpValue: true,
  makeContactRequiresAction: false,
  makeContactRequiresActionValue: true,
  unableToContactRequiresAction: false,
  unableToContactRequiresActionValue: true,
  source: false,
  sourceValue: "",
  stage: false,
  stageValue: "",
  location: true,
  locationContinent: "Europe",
  locationCountry: "",
  locationCity: "",
  statePartner: false,
  statePartnerValue: "unseen",
  stateUser: false,
  stateUserValue: "unseen",
  stateUserUser: "",
  firstRun: true,
  previousExits: false,
  previousExitsValue: 1,
  daysTopTech: false,
  daysTopTechValue: 180,
  createdAt: false,
  createdAtGT: dayjs().startOf("month").subtract(18, "months"),
  createdAtLT: dayjs().startOf("month").add(1, "months"),
  enteredStage: false,
  enteredStageGT: dayjs().startOf("month").subtract(18, "months"),
  enteredStageLT: dayjs().startOf("month").add(1, "months"),
  assignedTo: false,
  assignedToValue: "",
  organizationsSimilarTo: false,
  organizationsSimilarToValue: "",
  organizationsSimilarToName: "",
};

export const inboxStateDefaults = {
  counters: {},
  counterInbox: 0,
  reason: "",
  apiStatus: 200,
  firstRun: true,
  forceUpdate: false,
  offset: 0,
  total: 0,
  size: 10,
  organizations: null,
  orderedBy: "inboxes.inreachventures.updated_at",
  order: "desc",
  user: "roberto@inreachventures.com",
  aggregatedInboxes: {},
};

export const inboxReasons = [
  {
    reason: "inbox",
    title: "Inbox",
    icon: "bi-inboxes",
    filters: [],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "starred",
    title: "Review",
    icon: "bi-star",
    filters: [
      "is_this_interesting",
      "discover",
      "review",
      "review_form",
      "create",
    ],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "form",
    title: "Form",
    icon: "bi-input-cursor-text",
    filters: ["form_partial", "form_updated", "form"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "reconnect",
    title: "Reconnect",
    icon: "bi-alarm",
    filters: ["reconnect"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "call_feedback",
    title: "Recommendation Call",
    icon: "bi-phone-vibrate",
    filters: ["call_feedback"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "spam",
    title: "Quality Check",
    icon: "bi-radioactive",
    filters: ["is_this_spam"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "dig_machine_learning",
    title: "DIG Machine Learning",
    icon: "bi-cpu",
    filters: ["dig_ml"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "interesting_investors",
    title: "Interesting Investors",
    icon: "bi-wallet",
    filters: ["interesting_investors"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "dig_machine_learning_updated",
    title: "DIG ML Updated",
    icon: "bi-arrow-repeat",
    filters: ["dig_ml_updated"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "structured_feedback",
    title: "Structured Feedback",
    icon: "bi-arrow-repeat",
    filters: ["structured_feedback"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "ml_experiments",
    title: "ML Experiments",
    icon: "bi-graph-up-arrow",
    filters: ["ml_experiments"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "alert",
    title: "Alerts",
    icon: "bi-exclamation-triangle",
    filters: ["alert"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "pre_qualification",
    title: "Pre Qualification",
    icon: "bi-arrow-down-up",
    filters: ["pre_qualification"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "qualification",
    title: "Qualification",
    icon: "bi-easel",
    filters: ["qualification"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "unable_to_contact",
    title: "Unable to Contact",
    icon: "bi-slash-lg",
    filters: ["unable_to_contact"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "unable_to_reconnect",
    title: "Unable to Reconnect",
    icon: "bi-stopwatch",
    filters: ["unable_to_reconnect"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "schedule_call",
    title: "Schedule Call",
    icon: "bi-person",
    filters: ["schedule_call"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "social_messages",
    title: "Social Messages",
    icon: "bi-signal",
    filters: ["social_messages"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "funded",
    title: "Funded",
    icon: "bi-bank",
    filters: ["funded"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "missed_opportunity",
    title: "Missed Opportunity",
    icon: "bi-asterisk",
    filters: ["missed_opportunity"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "disagreement",
    title: "Feedback",
    icon: "bi-skip-backward",
    filters: ["disagreement", "feedback"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "no_show",
    title: "No Show",
    icon: "bi-eye-slash",
    filters: ["no_show"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "similarity",
    title: "You may also like...",
    icon: "bi-sliders2-vertical",
    filters: ["similarity"],
    experiments: [],
    ignore_experiments: [],
  },
  {
    reason: "more_mature",
    title: "More Mature",
    icon: "bi-card-checklist",
    filters: ["more_mature"],
    experiments: [],
    ignore_experiments: [],
  },
];
