import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { debounce } from "lodash";
import React, { useEffect } from "react";

import "../scss/OrganizationsSearchFixed.scoped.scss";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useCollapse, STATE } from "../../../../hooks/useTransitionControl";

function OrganizationsSearchFixed(props) {
  const { organizationsState, pageFilters } = props;
  const { width, height } = useWindowDimensions();
  const [state, toggle, enter] = useCollapse(
    350,
    STATE.ENTERED,
    "#organization-search-collapsable"
  );

  useEffect(
    debounce(() => {
      if (width >= 991 && state === STATE.EXITED) {
        enter();
      }
    }, 500),
    [width]
  );

  return (
    <div class="pb-3">
      <div class="d-flex" key={"random1"}>
        <a
          className={clsx(
            "btn btn-outline-secondary pb-3 organization-search-collapse-button",
            width >= 991 && "visually-hidden"
          )}
          href="#organization-search-collapsable"
          onClick={(event) => {
            event.preventDefault();
            toggle();
          }}
        >
          <i
            className={clsx(
              "bi",
              state !== "entered" && state !== "exited" && "bi-circle",
              state === "entered" && "bi-caret-up",
              state === "exited" && "bi-caret-down"
            )}
          ></i>
        </a>
      </div>
      <div
        class="border rounded-bottom p-3 organization-search-filter-container"
        style={{ height: width > 992 ? height - 145 : "auto" }}
        id="organization-search-collapsable"
      >
        <div class="row">
          <div class="col">
            <p class="fw-bolder mb-1 organization-search-results">
              {organizationsState.total} Results
            </p>
          </div>
          <div class="col">
            <p class="text-end mb-1 organization-search-pages">
              Page <b>{organizationsState.currentPage + 1}</b> of{" "}
              {organizationsState.pagesNumber}
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th scope="col">Parameter</th>
                  <th scope="col">Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.entries(pageFilters)
                  .sort()
                  .map(([key, value], i) => {
                    return (
                      <tr>
                        <td>{key}</td>
                        <td>{String(value).toString()}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(OrganizationsSearchFixed);
