import React from "react";

export default function OrganizationInvestmentStage(props) {
  const { investmentStage } = props;

  const classBuilder = (stage) => {
    let classes = "badge  me-1 fw-normal";
    if (
      [
        "pre-product",
        "pre-product-market-fit",
        "pre-marketing-at-scale",
      ].includes(stage)
    ) {
      classes += " bg-success";
    } else {
      classes += " bg-danger";
    }
    return classes;
  };

  return (
    <span className={classBuilder(investmentStage)}>{investmentStage}</span>
  );
}
