import { observer } from "mobx-react-lite";
import React from "react";

function PreviousExit(props) {
  const { organizationsState } = props;

  const setPreviousExits = (value) => {
    organizationsState.setOrganizations(null);
    organizationsState.setPreviousExitsValue(value);
    organizationsState.setOffset(0);
  };

  return (
    <div class="row g-0 rounded border p-1" style={{ background: "#fff" }}>
      {/* <div class="col"> */}
      <div class="input-group input-group-sm pb-1">
        <span class="input-group-text">Nº of People</span>
        <input
          value={organizationsState.previousExitsValue}
          type="number"
          class="form-control"
          min="0"
          step="1"
          onInput={(event) => {
            setPreviousExits(event.target.value);
          }}
        />
        <button
          class="btn btn-outline-secondary"
          type="button"
          disabled={organizationsState.previousExitsValue === 1}
          onClick={(event) => {
            setPreviousExits(1);
          }}
        >
          1+
        </button>
        <button
          class="btn btn-outline-secondary"
          disabled={organizationsState.previousExitsValue === 3}
          type="button"
          onClick={(event) => {
            setPreviousExits(3);
          }}
        >
          3+
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          disabled={organizationsState.previousExitsValue === 5}
          onClick={(event) => {
            setPreviousExits(5);
          }}
        >
          5+
        </button>
      </div>
    </div>
    // </div>
  );
}

export default observer(PreviousExit);
