import clsx from "clsx";
import { formatNumber } from "humanize-plus";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React from "react";

const useStyles = makeStyles((theme) => ({
  predictionsButton: {
    display: "flex",
    flexDirection: "column",
  },
  predictionsLabel: {
    fontSize: "0.75rem",
    fontWeight: "bolder",
    display: "block",
  },
  predictionsPercentage: {
    fontSize: "0.75rem",
    display: "block",
  },
}));

function OrganizationPredictions(props) {
  const { organization } = props;

  const classes = useStyles();

  return (
    <ButtonGroup color="secondary" size="small" variant="outlined">
      {Object.entries(organization.combined_predictions).map(([key, value]) => {
        return (
          <Button
            className={clsx(classes.predictionsButton)}
            component="div"
            children={
              <div>
                <Typography
                  className={classes.predictionsLabel}
                  variant={"body1"}
                >
                  {key}
                </Typography>
                <Typography
                  className={classes.predictionsPercentage}
                  variant={"body1"}
                >
                  {formatNumber(value * 100)}%
                </Typography>
              </div>
            }
          />
        );
      })}
    </ButtonGroup>
  );
}

export default observer(OrganizationPredictions);
