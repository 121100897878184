import React from "react";

export default function OrganizationHeadquarter(props) {
  const { locations } = props;

  const location =
    locations.filter((location) => location.is_hq)[0] || locations[0];

  return location.city ? (
    <span>
      <i>
        {location.city}, {location.country}
      </i>
    </span>
  ) : (
    <span>
      <i>{location.country}</i>
    </span>
  );
}
