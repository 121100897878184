import clsx from "clsx";
import { compactInteger } from "humanize-plus";
import { toJS } from "mobx";
import { observer } from "mobx-react-lite";
import { camelCase, cloneDeep, debounce, isEqual } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import "../scss/OrganizationsSearch.scoped.scss";

import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { useCollapse, STATE } from "../../../../hooks/useTransitionControl";

import AssignedTo from "./OrganizationSearch/AssignedTo";
import Classification from "./OrganizationSearch/Classification";
import ClassificationNot from "./OrganizationSearch/ClassificationNot";
import TrafficClassification from "./OrganizationSearch/TrafficClassification";
import Keywords from "./OrganizationSearch/Keywords";
import FiltersStatus from "./OrganizationSearch/FiltersStatus";
import FromTo from "./OrganizationSearch/FromTo";
import InvestmentType from "./OrganizationSearch/InvestmentType";
import InvestmentAmount from "./OrganizationSearch/InvestmentAmount";
import OnOff from "./OrganizationSearch/OnOff";
import Source from "./OrganizationSearch/Source";
import Stage from "./OrganizationSearch/Stage";
import Location from "./OrganizationSearch/Location";
import StatePartner from "./OrganizationSearch/StatePartner";
import StateUser from "./OrganizationSearch/StateUser";
import FiltersSetSave from "./OrganizationSearch/FiltersSetSave";
import FiltersSet from "./OrganizationSearch/FiltersSet";
import PreviousExit from "./OrganizationSearch/PreviousExit";
import DaysTopTech from "./OrganizationSearch/DaysTopTech";
import OrganizationsSimilarTo from "./OrganizationSearch/OrganizationsSimilarTo";

import { orderFactors, filters, filtersFallback } from "../../../../const";
import { collectUrlSearch } from "../helpers";

const filtersComponents = {
  assignedTo: AssignedTo,
  classification: Classification,
  classificationNot: ClassificationNot,
  trafficClassification: TrafficClassification,
  foundedOn: FromTo,
  createdAt: FromTo,
  enteredStage: FromTo,
  keywords: Keywords,
  investmentAmount: InvestmentAmount,
  investmentType: InvestmentType,
  hasPredictions: OnOff,
  hasMissingData: OnOff,
  isClosed: OnOff,
  isComplete: OnOff,
  isInInbox: OnOff,
  isSpam: OnOff,
  isZombie: OnOff,
  requiresMoreInfo: OnOff,
  requiresManualHelp: OnOff,
  source: Source,
  stage: Stage,
  location: Location,
  statePartner: StatePartner,
  stateUser: StateUser,
  previousExits: PreviousExit,
  daysTopTech: DaysTopTech,
  makeContactRequiresAction: OnOff,
  unableToContactRequiresAction: OnOff,
  organizationsSimilarTo: OrganizationsSimilarTo,
};

function OrganizationsSearch(props) {
  const {
    collectToken,
    customDispatch,
    customState,
    handleResetFilters,
    organizationsState,
    organizationsTriggerCustom,
    navVisible,
    userState,
  } = props;
  const { width, height } = useWindowDimensions();
  const [state, toggle, enter] = useCollapse(
    350,
    STATE.ENTERED,
    "#organization-search-collapsable"
  );
  const location = useLocation();
  const organizationsSearchFilterContainerTop = useRef(null);
  const [currentFiltersLabels, setCurrentFiltersLabels] = useState([]);

  const removeFilters = (event) => {
    if (event) event.preventDefault();
    handleResetFilters();
  };

  const loadFilters = (event) => {
    event.preventDefault();
    removeFilters();

    const loadFiltersParams = toJS(
      userState.filtersSets[event.target.dataset["set"]]
    );

    collectUrlSearch(loadFiltersParams, organizationsState);
  };

  const loadDefaultFilters = () => {
    removeFilters();
    collectUrlSearch(
      userState.defaultFilters[location.pathname] ||
        filtersFallback[location.pathname],
      organizationsState
    );
  };

  const resolveFilterComponent = (filter, label) => {
    const FilterComponent = filtersComponents[filter];
    return (
      <FilterComponent
        filter={filter}
        label={label}
        organizationsState={organizationsState}
        userState={userState}
      />
    );
  };

  const isEqualCurrentFiltersWithDefault = () => {
    if (!location.search) {
      return false;
    }
    const defaultFilters = Object.keys(userState.defaultFilters).length
      ? cloneDeep(userState.defaultFilters)[location.pathname]
      : cloneDeep(filtersFallback)[location.pathname];
    const currentFilters = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    );
    delete currentFilters.limit;
    delete currentFilters.name;
    delete currentFilters.offset;
    return isEqual(defaultFilters, currentFilters);
  };

  const isCurrentFiltersInFiltersSets = () => {
    const currentFilters = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    );
    delete currentFilters.limit;
    delete currentFilters.name;
    delete currentFilters.offset;

    const currentFiltersSet = Object.entries(userState.filtersSets).find(
      ([key, filtersSet]) => isEqual(currentFilters, filtersSet)
    );

    return currentFiltersSet ? currentFiltersSet.shift() : null;
  };

  const isCurrentFiltersEmpty = () => {
    const currentFilters = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    );
    delete currentFilters.limit;
    delete currentFilters.name;
    delete currentFilters.offset;
    delete currentFilters.order;
    delete currentFilters.order_by;
    return Object.keys(currentFilters).length === 0;
  };

  useEffect(
    debounce(() => {
      if (width >= 991 && state === STATE.EXITED) {
        enter();
      }
    }, 500),
    [width]
  );

  useEffect(() => {
    if (
      !location.search &&
      !isEqualCurrentFiltersWithDefault() &&
      location.state?.from === location.pathname
    ) {
      loadDefaultFilters();
    }
  }, [location]);

  useEffect(() => {
    setCurrentFiltersLabels(
      [
        isEqualCurrentFiltersWithDefault() ? "Default" : null,
        isCurrentFiltersInFiltersSets(),
      ].filter((label) => label !== null)
    );
  }, [location, userState.filtersSets]);

  if (organizationsState.mode === "custom") {
    return (
      <div
        className={clsx(
          "organization-search-fill-height",
          navVisible && width > 992 && "unstuck"
        )}
        style={{
          height:
            width > 992
              ? navVisible
                ? `calc(100vh - ${
                    document.getElementById("nav").offsetHeight + 15
                  }px)`
                : "auto"
              : "auto",
        }}
      >
        <div ref={organizationsSearchFilterContainerTop}>
          <div class="mb-2 d-grid">
            <button
              class="btn btn-sm btn-outline-secondary"
              onClick={() => {
                organizationsState.setOrganizations(null);
                organizationsState.setOffset(0);
                organizationsState.setMode("assisted");
              }}
            >
              Switch to assisted mode
            </button>
          </div>
          <div class="row">
            <div class="col">
              <p class="fw-bolder mb-1 organization-search-results">
                {compactInteger(organizationsState.total, 0)} Results
              </p>
            </div>
            <div class="col">
              <p class="text-end mb-1 organization-search-pages">
                Page <b>{organizationsState.currentPage + 1}</b> of{" "}
                {organizationsState.pagesNumber + 1}
              </p>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            "border",
            "rounded-bottom",
            "p-3",
            "organization-search-filter-container",
            !navVisible && width > 992 && "stuck"
          )}
          style={{
            top: organizationsSearchFilterContainerTop?.current?.offsetHeight,
          }}
          id="organization-search-collapsable"
        >
          {customState !== 0 &&
            customState.map(([key, value], i) => {
              return (
                <div class="row mb-1" key={i}>
                  <div class="col-6">
                    <input
                      class="form-control-sm form-control organizations-search-input-custom"
                      type="text"
                      value={key}
                      placeholder="Key"
                      onInput={(event) => {
                        customDispatch({
                          type: "update_key",
                          value: {
                            index: i,
                            value: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div class="col-4">
                    <input
                      class="form-control-sm form-control organizations-search-input-custom"
                      type="text"
                      value={value}
                      placeholder="Value"
                      onInput={(event) => {
                        customDispatch({
                          type: "update_value",
                          value: {
                            index: i,
                            value: event.target.value,
                          },
                        });
                      }}
                    />
                  </div>
                  <div class="col-2">
                    <button
                      class="btn btn-sm btn-outline-secondary"
                      type="button"
                      value={value}
                      placeholder="Value"
                      onClick={(event) => {
                        customDispatch({ type: "remove", value: i });
                      }}
                    >
                      <i class="bi bi-dash-circle"></i>
                    </button>
                  </div>
                </div>
              );
            })}
          <div class="d-grid gap-2 col mx-auto">
            <button
              type="button"
              class="btn-sm btn btn-outline-secondary"
              onClick={(event) => {
                customDispatch({ type: "add", value: ["", ""] });
              }}
            >
              Add
            </button>
            <button
              type="button"
              class="btn btn-outline-primary"
              onClick={(event) => {
                organizationsTriggerCustom(customState);
              }}
            >
              Apply
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={clsx(
          "organization-search-fill-height",
          navVisible && width > 992 && "unstuck"
        )}
        style={{
          height:
            width > 992
              ? navVisible
                ? `calc(100vh - ${
                    document.getElementById("nav").offsetHeight + 15
                  }px)`
                : "auto"
              : "auto",
        }}
      >
        <div ref={organizationsSearchFilterContainerTop}>
          {userState.roles.includes("dev") && (
            <div class="mb-2 d-grid">
              <button
                class="btn btn-sm btn-outline-secondary"
                onClick={() => {
                  organizationsState.setOrganizations(null);
                  organizationsState.setOffset(0);
                  organizationsState.setMode("custom");
                  organizationsTriggerCustom(customState);
                }}
              >
                Switch to custom mode
              </button>
            </div>
          )}
          <div class="row">
            <div class="col">
              <p class="fw-bolder mb-1 organization-search-results">
                {compactInteger(organizationsState.total, 0)} Results
              </p>
            </div>
            <div class="col">
              <p class="text-end mb-1 organization-search-pages">
                Page <b>{organizationsState.currentPage + 1}</b> of{" "}
                {organizationsState.pagesNumber + 1}
              </p>
            </div>
          </div>
          <div class="d-flex" key={"random1"}>
            <div
              key={"random2"}
              class="input-group organization-search"
              className={clsx(
                "input-group organization-search",
                width >= 991 && "me-0 mb-2",
                width < 991 && "me-2"
              )}
            >
              <input
                key={"searchByInput"}
                class="form-control border-secondary"
                type="search"
                placeholder={
                  organizationsState.nameFlag ? "company name" : "example.com"
                }
                value={organizationsState.name}
                onInput={(event) => {
                  organizationsState.setName(event.target.value);
                }}
              />
              <button
                class="btn btn-outline-secondary dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
              >
                {organizationsState.nameFlag
                  ? "Search by Name"
                  : "Search by Domain"}
              </button>
              <ul class="dropdown-menu dropdown-menu-end">
                <li>
                  <button
                    class="dropdown-item"
                    onClick={() => {
                      if (!organizationsState.nameFlag) {
                        organizationsState.setOrganizations(null);
                        organizationsState.setOffset(0);
                      }
                      organizationsState.setNameFlag(true);
                    }}
                  >
                    Search By Name
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    onClick={() => {
                      if (organizationsState.nameFlag) {
                        organizationsState.setOrganizations(null);
                        organizationsState.setOffset(0);
                      }
                      organizationsState.setNameFlag(false);
                    }}
                  >
                    Search By Domain
                  </button>
                </li>
              </ul>
            </div>
            <a
              className={clsx(
                "btn btn-outline-secondary pb-3 organization-search-collapse-button",
                width >= 991 && "visually-hidden"
              )}
              href="#organization-search-collapsable"
              onClick={(event) => {
                event.preventDefault();
                toggle();
              }}
            >
              <i
                className={clsx(
                  "bi",
                  state !== "entered" && state !== "exited" && "bi-circle",
                  state === "entered" && "bi-caret-up",
                  state === "exited" && "bi-caret-down"
                )}
              ></i>
            </a>
          </div>
        </div>
        <div
          className={clsx(
            "border",
            "rounded-bottom",
            "p-3",
            "organization-search-filter-container",
            !navVisible && width > 992 && "stuck"
          )}
          style={{
            top: organizationsSearchFilterContainerTop?.current?.offsetHeight,
          }}
        >
          <div>
            <div class="row">
              <div class="col-12 mb-3">
                <div class="row">
                  <div class="col-12">
                    <span class="organization-search-filters-set-label">
                      Filters Set:
                    </span>
                    <FiltersSet
                      currentFilters={Object.fromEntries(
                        new URLSearchParams(location.search).entries()
                      )}
                      currentFiltersLabels={currentFiltersLabels}
                      loadDefaultFilters={loadDefaultFilters}
                      loadFilters={loadFilters}
                      isCurrentFiltersEmpty={isCurrentFiltersEmpty}
                      removeFilters={removeFilters}
                      userState={userState}
                    />
                    <FiltersSetSave
                      collectToken={collectToken}
                      isCurrentFiltersInFiltersSets={
                        isCurrentFiltersInFiltersSets
                      }
                      isEqualCurrentFiltersWithDefault={
                        isEqualCurrentFiltersWithDefault
                      }
                      userState={userState}
                    />
                  </div>
                </div>
              </div>
              <div class="col-12">
                <FiltersStatus
                  organizationsState={organizationsState}
                  userState={userState}
                />
              </div>
            </div>
          </div>
          <div id="organization-search-collapsable">
            <div class="row mb-3">
              <div class="col-12 border bg-light rounded organization-search-widget-container">
                <span class="organization-search-widget-label">
                  {filters[organizationsState.currentWidget] ||
                    "Add a new filter or edit an existing one"}
                </span>
                {organizationsState.currentWidget !== "" &&
                  resolveFilterComponent(
                    organizationsState.currentWidget,
                    filters[organizationsState.currentWidget]
                  )}
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="row mb-3">
                  <div class="input-group">
                    <label class="input-group-text">Filters</label>
                  </div>
                </div>
                <div class="d-flex flex-wrap mb-3">
                  {Object.entries(filters).map(([filter, label], i) => {
                    if (filter.startsWith("separator")) {
                      return <div class="separator">{label}</div>;
                    } else {
                      return (
                        <div class="form-check form-switch organization-search-filter-switch-container">
                          <input
                            class="form-check-input organization-search-filter-switch"
                            type="checkbox"
                            checked={organizationsState[filter]}
                            id={i}
                            data-testid={filter}
                            onClick={() => {
                              organizationsState.setOrganizations(null);
                              organizationsState.setOffset(0);
                              if (organizationsState[filter]) {
                                organizationsState[camelCase("set_" + filter)](
                                  false
                                );
                                organizationsState.setCurrentWidget("");
                              } else {
                                organizationsState[camelCase("set_" + filter)](
                                  true
                                );
                                organizationsState.setCurrentWidget(filter);
                              }
                            }}
                          />
                          <label
                            class="form-check-label organization-search-filter-label"
                            for={i}
                          >
                            {label}
                          </label>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
              <div class="col-12">
                <div class="input-group mb-3">
                  <label class="input-group-text">Sort By</label>
                  <select
                    onChange={(event) => {
                      organizationsState.setOrganizations(null);
                      organizationsState.setOrderedBy(event.target.value);
                      organizationsState.setOffset(0);
                      event.preventDefault();
                    }}
                    class="form-select form-select-sm"
                    id="order-by-select"
                  >
                    {Object.entries(orderFactors).map(
                      ([orderFactor, orderLabel]) => {
                        return (
                          <option
                            selected={
                              organizationsState.orderedBy === orderFactor
                            }
                            value={orderFactor}
                          >
                            {orderLabel}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div class="input-group">
                  <label class="input-group-text" for="inputGroupSelect01">
                    Order
                  </label>
                  <select
                    onChange={(event) => {
                      organizationsState.setOrganizations(null);
                      organizationsState.setOrder(event.target.value);
                      organizationsState.setOffset(0);
                      event.preventDefault();
                    }}
                    class="form-select form-select-sm"
                    id="order-by-select"
                  >
                    <option
                      selected={organizationsState.order === "asc"}
                      value="asc"
                    >
                      Ascending
                    </option>
                    <option
                      selected={organizationsState.order === "desc"}
                      value="desc"
                    >
                      Descending
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default observer(OrganizationsSearch);
