import clsx from "clsx";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { compactInteger, formatNumber, truncateWords } from "humanize-plus";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import "../scss/OrganizationCard.scoped.scss";

import OrganizationFounded from "./OrganizationCard/OrganizationFounded";
import OrganizationHeadquarter from "./OrganizationCard/OrganizationHeadquarter";
import OrganizationInvestmentStage from "./OrganizationCard/OrganizationInvestmentStage";
import OrganizationInvestmentType from "./OrganizationCard/OrganizationInvestmentType";
import OrganizationLinks from "./OrganizationCard/OrganizationLinks";
import OrganizationPredictions from "./OrganizationCard/OrganizationPredictions";
import OrganizationTraction from "./OrganizationCard/OrganizationTraction";

dayjs.extend(relativeTime);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    marginBottom: "1rem",
    boxShadow: "none",
    border: "1px solid #dee2e6",
  },
  header: {
    borderBottom: "1px solid #dee2e6",
  },
  avatar: {
    objectFit: "scale-down",
  },
  link: {
    color: "rgb(93, 171, 73)",
  },
  description: {
    display: "inline-block",
    width: "calc(100% - 24px)",
    float: "right",
    fontSize: "0.75rem",
    lineHeight: "1.7rem",
  },
  showMore: {
    float: "clear",
    fontSize: "0.75rem",
  },
}));

function OrganizationCard(props) {
  const { organization, organizationsState } = props;

  const [showDescription, setShowDescription] = useState(true);

  const classes = useStyles();

  const peopleClassBuilder = (min, max) => {
    let classes = "badge ms-3 d-inline-block fw-normal";
    if (max <= 50 && max >= 0 && min >= 0) {
      classes += " bg-success";
    } else {
      classes += " bg-danger";
    }
    return classes;
  };

  const lastFundClassBuilder = (value) => {
    let classes = "badge ms-3 d-inline-block fw-normal";
    if (value <= 2000000) {
      classes += " bg-success";
    } else {
      classes += " bg-danger";
    }
    return classes;
  };

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        avatar={
          <Avatar className={classes.avatar} src={organization.image_url}>
            <i class="bi bi-building"></i>
          </Avatar>
        }
        title={
          <div>
            <Typography variant="h5">
              <Link
                className={classes.link}
                href={"/organizations/" + organization.id}
              >
                {organization.name}
              </Link>
            </Typography>
          </div>
        }
        subheader={
          <div>
            Founded in&nbsp;
            <OrganizationHeadquarter locations={organization.locations || []} />
            &nbsp;on&nbsp;
            <OrganizationFounded foundedOn={organization.founded_on} />
            <br />
            <OrganizationInvestmentType
              investmentType={
                organization?.ml_features?.investment_type_text_clf?.transformed
              }
            />
            <OrganizationInvestmentStage
              investmentStage={
                organization?.ml_features?.investment_stage?.transformed
              }
            />
            <br />
            {organizationsState.gridView && (
              <OrganizationLinks
                crunchbase={organization?.crunchbase}
                linkedin={organization?.linkedin}
                name={organization.name}
                twitter={organization?.twitter}
                homepage_url={organization?.homepage_url}
                id={organization.id}
              />
            )}
          </div>
        }
        action={
          !organizationsState.gridView && (
            <div class="d-flex flex-column justify-content-end">
              <OrganizationLinks
                crunchbase={organization?.crunchbase}
                linkedin={organization?.linkedin}
                name={organization.name}
                twitter={organization?.twitter}
                homepage_url={organization?.homepage_url}
                id={organization.id}
              />
              <OrganizationPredictions organization={organization} />
            </div>
          )
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid container item sm={12} spacing={2}>
            <Grid item sm={4}>
              <i class="bi-cash d-inline-block"></i>
              {organization.total_funding_usd ? (
                <span
                  className={lastFundClassBuilder(
                    organization.total_funding_usd
                  )}
                >
                  {compactInteger(organization?.total_funding_usd)} on{" "}
                  {dayjs(organization.founded_on).format("MMM-YYYY")}
                </span>
              ) : (
                <span class="badge ms-3 d-inline-block fw-normal bg-light text-dark">
                  no data
                </span>
              )}
            </Grid>
            <Grid item sm={4}>
              <i class="bi-wallet2 d-inline-block"></i>
            </Grid>
            <Grid item sm={4}>
              <i class="bi-people d-inline-block"></i>
              <span
                className={peopleClassBuilder(
                  organization.num_employees_min,
                  organization.num_employees_max
                )}
              >
                {organization.num_employees_min || 0} -{" "}
                {organization.num_employees_max || 0} (
                {organization?.person_ids?.length || 0} in DIG)
              </span>
            </Grid>
          </Grid>
          <Grid item sm={12}>
            <i class="bi-blockquote-left d-inline-block"></i>
            <Typography className={classes.description}>
              {!showDescription
                ? organization.description
                : truncateWords(organization.description, 30)}
            </Typography>
            <Typography paragraph align="right" className={classes.showMore}>
              {showDescription && truncateWords(organization.description, 30) && (
                <Link
                  onClick={(event) => {
                    event.preventDefault();
                    setShowDescription(false);
                  }}
                >
                  Show More
                </Link>
              )}
              {!showDescription && (
                <Link
                  onClick={(event) => {
                    event.preventDefault();
                    setShowDescription(true);
                  }}
                >
                  Hide
                </Link>
              )}
            </Typography>
          </Grid>
          {/* <Grid item sm={organizationsState.gridView ? 12 : 6}>
            <Typography variant="h6">
              <i class="bi-bar-chart"></i> Traction
            </Typography>
            <OrganizationTraction
              traction={
                organization?.ml_features?.organization_trend?.transformed
              }
            />
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
}

export default observer(OrganizationCard);
