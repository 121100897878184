import axios from "axios";
import clsx from "clsx";
import { oxford } from "humanize-plus";
import { sha256 } from "js-sha256";
import { cloneDeep } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import config from "../../../../../config";

function FiltersSet(props) {
  const {
    currentFilters,
    currentFiltersLabels,
    isCurrentFiltersEmpty,
    loadDefaultFilters,
    loadFilters,
    removeFilters,
    userState,
  } = props;

  const [loading, setLoading] = useState(true);
  const [disabled, setDisabled] = useState(false);
  const [subscribed, setSubscribed] = useState(null);

  const url = config.api.host + "/alerts";

  const handleButton = async (event) => {
    setDisabled(true);
    if (!subscribed) {
      const searchParams = new URLSearchParams({
        access_token: userState.token,
        client: userState.client,
      });
      const alertUrl = new URL(url);
      alertUrl.search = searchParams.toString();

      const organizationSearchParams = cloneDeep(currentFilters);
      delete organizationSearchParams.limit;
      delete organizationSearchParams.name;
      delete organizationSearchParams.offset;

      const postBody = {
        title: currentFiltersLabels[0],
        type: "ORGANIZATIONS",
        organization_search_params: organizationSearchParams,
        client: userState.client,
        user_id: userState.user.email,
      };

      try {
        const response = await axios.post(alertUrl, postBody, {
          headers: {
            Authorization: "Bearer " + userState.token,
            "Content-Type": "application/json",
          },
        });
        setSubscribed(true);
      } catch (error) {
        setSubscribed(false);
      }
    } else {
      const searchParams = new URLSearchParams({
        access_token: userState.token,
        client: userState.client,
      });
      const alertUrl = new URL(
        `${url}/${userState.client}-${
          userState.user.email
        }-ORGANIZATIONS-${sha256(currentFiltersLabels[0])}`
      );
      alertUrl.search = searchParams.toString();

      try {
        const response = await axios.delete(alertUrl, {
          headers: {
            Authorization: "Bearer " + userState.token,
          },
        });
        setSubscribed(false);
      } catch (error) {
        setSubscribed(true);
      }
    }
    setDisabled(false);
  };

  useEffect(() => {
    const checkAlert = async () => {
      const searchParams = new URLSearchParams({
        access_token: userState.token,
        client: userState.client,
      });
      const alertUrl = new URL(
        `${url}/${userState.client}-${
          userState.user.email
        }-ORGANIZATIONS-${sha256(currentFiltersLabels[0])}`
      );
      alertUrl.search = searchParams.toString();

      try {
        const response = await axios.get(alertUrl, {
          headers: {
            Authorization: "Bearer " + userState.token,
          },
        });
        setLoading(false);
        setSubscribed(true);
      } catch (error) {
        setLoading(false);
        setSubscribed(false);
      }
    };
    if (currentFiltersLabels.length > 0) {
      checkAlert();
    }
  }, [currentFiltersLabels]);

  return (
    <>
      <div class="btn-group me-1">
        <button
          class="btn btn-outline-primary btn-sm"
          type="button"
          disabled={true}
        >
          {currentFiltersLabels.length > 0 && (
            <span>
              <span class="bi bi-check"></span> {oxford(currentFiltersLabels)}
            </span>
          )}
          {currentFiltersLabels.length === 0 && (
            <span>
              <span class="bi bi-funnel"></span> Custom
            </span>
          )}
        </button>
        <button
          type="button"
          class="btn btn-sm btn-outline-primary dropdown-toggle dropdown-toggle-split"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        ></button>
        <ul class="dropdown-menu dropdown-menu-sm-end">
          <li>
            <button
              onClick={loadDefaultFilters}
              disabled={currentFiltersLabels.includes("Default")}
              class="dropdown-item btn-sm btn btn-link link-dark"
            >
              <span class="bi bi-filter-circle"></span> Default
            </button>
          </li>
          <li>
            {Object.keys(userState.filtersSets).map((filtersSet) => {
              return (
                <button
                  onClick={loadFilters}
                  class="dropdown-item btn-sm btn btn-link link-dark"
                  disabled={currentFiltersLabels.includes(filtersSet)}
                  data-set={filtersSet}
                >
                  <span class="bi bi-filter-circle"></span> {filtersSet}
                </button>
              );
            })}
          </li>
          <li>
            <hr class="dropdown-divider" />
          </li>
          <li>
            <button
              onClick={removeFilters}
              disabled={isCurrentFiltersEmpty()}
              class="dropdown-item btn-sm btn btn-link link-dark"
            >
              <span class="bi bi-x-octagon"></span> Clear All
            </button>
          </li>
        </ul>
      </div>
      {currentFiltersLabels.length > 0 &&
        currentFiltersLabels[0] !== "Default" && (
          <button
            type="button"
            class={clsx(
              "btn",
              "btn-sm",
              !subscribed && "btn-outline-secondary",
              subscribed && "btn-secondary"
            )}
            disabled={disabled || loading}
            onClick={handleButton}
          >
            <i
              class={clsx(
                "bi",
                "bi-bell",
                subscribed && "bi-bell",
                !subscribed && "bi-bell-slash"
              )}
            />
            Alert Me
          </button>
        )}
    </>
  );
}

export default observer(FiltersSet);
