import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useMemo, useState } from "react";

import config from "../../../../../config";

import "../../scss/OrganizationsSimilarTo.scoped.scss";

function OrganizationsSimilarTo(props) {
  const { organizationsState, userState } = props;

  const [show, setShow] = useState(false);
  const [organizations, setOrganizations] = useState([]);

  const autoSuggest = async (name) => {
    const urlParams = new URLSearchParams({
      limit: 5,
      access_token: userState.token,
      client: userState.client,
      order_by: "score",
      order: "desc",
      name,
    });

    const response = await fetch(
      `${config.api.host}/organizations?${urlParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();

    setOrganizations(json.organizations || []);
  };

  const collectName = async (id) => {
    const urlParams = new URLSearchParams({
      access_token: userState.token,
      client: userState.client,
    });

    const response = await fetch(
      `${config.api.host}/organizations/${id}?${urlParams.toString()}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    const json = await response.json();

    organizationsState.setOrganizationsSimilarToName(json.name);
  };

  const autoSuggestDebounced = useMemo(() => debounce(autoSuggest, 400), []);

  useEffect(() => {
    if (organizationsState.organizationsSimilarToValue) {
      collectName(organizationsState.organizationsSimilarToValue);
    }
  }, [organizationsState.organizationsSimilarToValue]);

  useEffect(() => {
    const currentOrganizationsNames = organizations.map((o) => o.name);
    if (
      organizationsState.organizationsSimilarToName &&
      !currentOrganizationsNames.includes(
        organizationsState.organizationsSimilarToName
      )
    )
      autoSuggestDebounced(organizationsState.organizationsSimilarToName);
  }, [organizationsState.organizationsSimilarToName]);

  return (
    <div
      id="input"
      class="row g-0 rounded border p-1"
      style={{ background: "#fff" }}
    >
      <div class="input-group input-group-sm pb-1">
        <span class="input-group-text">Company</span>
        <input
          value={organizationsState.organizationsSimilarToName}
          type="text"
          class="form-control"
          onInput={(event) => {
            setShow(true);
            organizationsState.setOrganizationsSimilarToName(
              event.target.value
            );
          }}
          onPointerDown={(event) =>
            organizationsState.setOrganizationsSimilarToName("")
          }
        />
      </div>
      {show && organizations.length > 0 && (
        <div id="autosuggest">
          <ul class="list-group">
            {organizations.map((organization) => (
              <li key={organization.id} class="list-group-item">
                <button
                  class="btn btn-sm btn-link"
                  onClick={(e) => {
                    organizationsState.setOrganizations(null);
                    organizationsState.setOffset(0);
                    organizationsState.setOrganizationsSimilarToValue(
                      organization.id
                    );
                    organizationsState.setOrganizationsSimilarToName(
                      organization.name
                    );
                    setShow(false);
                  }}
                >
                  {organization.name}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default observer(OrganizationsSimilarTo);
