import country from "country-list-js";
import { observer } from "mobx-react-lite";
import React from "react";

import "../../scss/Location.scoped.scss";

function Stage(props) {
  const { organizationsState, filter, label } = props;

  return (
    <div class="g-0 rounded border p-1" style={{ background: "#fff" }}>
      <div class="input-group input-group-sm mb-1">
        <label for="continentSelect" class="input-group-text">
          Continent
        </label>
        <select
          class="form-select form-select-sm"
          id="continentSelect"
          onChange={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState.setLocationContinent(event.target.value);
            organizationsState.setLocationCountry("");
          }}
        >
          <option
            selected={organizationsState.locationContinent === ""}
            value=""
          >
            All
          </option>
          {country
            .continents()
            .sort()
            .map((continent) => (
              <option
                selected={organizationsState.locationContinent === continent}
                value={continent}
              >
                {continent}
              </option>
            ))}
        </select>
      </div>
      {organizationsState.locationContinent && (
        <div class="mb-1 input-group input-group-sm">
          <label for="countrySelect" class="input-group-text">
            Country
          </label>
          <select
            class="form-select form-select-sm"
            id="countrySelect"
            onChange={(event) => {
              organizationsState.setOrganizations(null);
              organizationsState.setOffset(0);
              organizationsState.setLocationCountry(event.target.value);
              organizationsState.setLocationCity("");
            }}
          >
            <option
              selected={organizationsState.locationCountry === ""}
              value=""
            >
              All
            </option>
            {Object.values(country.all)
              .filter(
                (country) =>
                  country.continent === organizationsState.locationContinent
              )
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((country) => (
                <option
                  selected={organizationsState.locationCountry === country.name}
                  value={country.name}
                >
                  {country.name}
                </option>
              ))}
          </select>
        </div>
      )}
      {organizationsState.locationCountry && (
        <div class="input-group input-group-sm">
          <span class="input-group-text">City</span>
          <input
            onChange={(event) => {
              organizationsState.setOrganizations(null);
              organizationsState.setOffset(0);
              organizationsState.setLocationCity(event.target.value);
              event.preventDefault();
            }}
            value={organizationsState.locationCity}
            type="search"
            class="form-control"
          />
        </div>
      )}
    </div>
  );
}

export default observer(Stage);
