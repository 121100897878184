import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React from "react";

import "../scss/OrganizationsPagination.scoped.scss";

function OrganizationsPagination(props) {
  const { organizationsState } = props;

  const moveToPage = (i) => {
    const offset = i * organizationsState.size;
    organizationsState.setOrganizations(null);
    organizationsState.setOffset(offset);
  };

  const moveNext = () => {
    if (!organizationsState.nextDisabled) {
      const nextOffset = organizationsState.offset + organizationsState.size;
      organizationsState.setOrganizations(null);
      organizationsState.setOffset(nextOffset);
    }
  };

  const movePrevious = (i) => {
    if (!organizationsState.previousDisabled) {
      const previousOffset =
        organizationsState.offset - organizationsState.size;
      organizationsState.setOrganizations(null);
      organizationsState.setOffset(previousOffset);
    }
  };

  const moveFirst = () => {
    const nextOffset = 0;
    organizationsState.setOrganizations(null);
    organizationsState.setOffset(nextOffset);
  };

  const moveLast = () => {
    const nextOffset = organizationsState.size * organizationsState.pagesNumber;
    organizationsState.setOrganizations(null);
    organizationsState.setOffset(nextOffset);
  };

  if (
    (organizationsState.previousDisabled && organizationsState.nextDisabled) ||
    organizationsState.organizations === null
  ) {
    return;
  }

  return (
    <nav>
      <ul class="pagination justify-content-center">
        <li
          className={clsx(
            "page-item",
            organizationsState.previousDisabled && "disabled"
          )}
        >
          <button
            class="page-link"
            onClick={(event) => {
              moveFirst();
              event.preventDefault();
            }}
          >
            First
          </button>
        </li>
        <li
          className={clsx(
            "page-item",
            organizationsState.previousDisabled && "disabled"
          )}
        >
          <button
            class="page-link"
            onClick={(event) => {
              movePrevious();
              event.preventDefault();
            }}
          >
            Previous
          </button>
        </li>
        {[...Array(organizationsState.pagesNumber + 1).keys()]
          .slice(organizationsState.bottomPage, organizationsState.topPage)
          .map((i) => {
            return (
              <li
                className={clsx(
                  "page-item",
                  i === organizationsState.currentPage && "active disabled"
                )}
              >
                <button
                  class="page-link"
                  onClick={(event) => {
                    moveToPage(i);
                    event.preventDefault();
                  }}
                >
                  {i + 1}
                </button>
              </li>
            );
          })}
        <li
          className={clsx(
            "page-item",
            organizationsState.nextDisabled && "disabled"
          )}
        >
          <button
            class="page-link"
            onClick={(event) => {
              moveNext();
              event.preventDefault();
            }}
          >
            Next
          </button>
        </li>
        <li
          className={clsx(
            "page-item",
            organizationsState.nextDisabled && "disabled"
          )}
        >
          <button
            class="page-link"
            onClick={(event) => {
              moveLast();
              event.preventDefault();
            }}
          >
            Last
          </button>
        </li>
      </ul>
    </nav>
  );
}

export default observer(OrganizationsPagination);
