import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";

const theme = createMuiTheme({
  palette: {
    primary: {
      //main: "#5dab49",
      main: "#ffffff",
    },
    secondary: {
      //main: "#337ab7",
      main: "#5dab49",
    },
    success: {
      main: "#52ab76",
    },
    danger: {
      main: "#ab4038",
    },
  },
});

import App from "./components/App";
import AppGuard from "../../../components/AppGuard";
import Incomplete from "./components/Incomplete";

export default function Root(props) {
  const {
    auth0,
    discoverState,
    inboxState,
    incompleteState,
    organizationsState,
    mountParcel,
    spamState,
    userState,
  } = props;

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Switch>
          <Route
            path="/discover"
            render={() => (
              <AppGuard
                app={
                  <App
                    auth0={auth0}
                    inboxState={inboxState}
                    organizationsState={discoverState}
                    mountParcel={mountParcel}
                    userState={userState}
                  />
                }
                auth0={auth0}
                freeEntry={false}
                userState={userState}
              />
            )}
          />
          <Route
            path="/organizations"
            render={() => (
              <AppGuard
                app={
                  <App
                    auth0={auth0}
                    inboxState={inboxState}
                    organizationsState={organizationsState}
                    mountParcel={mountParcel}
                    userState={userState}
                  />
                }
                auth0={auth0}
                freeEntry={true}
                userState={userState}
              />
            )}
          />
          <Route
            path="/spam"
            render={() => (
              <AppGuard
                app={
                  <App
                    auth0={auth0}
                    inboxState={inboxState}
                    organizationsState={spamState}
                    mountParcel={mountParcel}
                    userState={userState}
                  />
                }
                auth0={auth0}
                freeEntry={false}
                userState={userState}
              />
            )}
          />
          <Route
            path="/incomplete"
            render={() => (
              <AppGuard
                app={
                  <Incomplete
                    auth0={auth0}
                    inboxState={inboxState}
                    organizationsState={incompleteState}
                    mountParcel={mountParcel}
                    userState={userState}
                  />
                }
                auth0={auth0}
                freeEntry={false}
                userState={userState}
              />
            )}
          />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}
