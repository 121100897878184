import { useEffect, useState } from "react";

export const STATE = {
  ENTERING: "entering",
  ENTERED: "entered",
  EXITING: "exiting",
  EXITED: "exited",
};

const defaultCollapseClasses = {
  entering: "collapsing",
  entered: "collpase show",
  exiting: "collapsing",
  exited: "collapse",
};

function useTransitionState(duration = 350, initial = STATE.EXITED) {
  const [state, setState] = useState(initial);

  useEffect(() => {
    let timerId;
    if (state === STATE.ENTERING) {
      timerId = setTimeout(() => setState(STATE.ENTERED), duration);
    } else if (state === STATE.EXITING) {
      timerId = setTimeout(() => setState(STATE.EXITED), duration);
    }

    return () => {
      timerId && clearTimeout(timerId);
    };
  });

  return [state, setState];
}

export default function useTransitionControl(duration, initial) {
  const [state, setState] = useTransitionState(duration, initial);

  const enter = () => {
    if (state !== STATE.EXITING) {
      setState(STATE.ENTERING);
    }
  };

  const exit = () => {
    if (state !== STATE.ENTERING) {
      setState(STATE.EXITING);
    }
  };

  return [state, enter, exit];
}

export function useCollapse(
  duration,
  initial,
  collapsableId,
  classes = defaultCollapseClasses,
  isLoading = false
) {
  const [state, enter, exit] = useTransitionControl(duration, initial);
  const [height, setHeight] = useState(null);
  const [collapsable, setCollapsable] = useState(null);

  const toggle = () => {
    if (state === STATE.ENTERED) {
      exit();
    } else if (state === STATE.EXITED) {
      enter();
    }
  };

  useEffect(() => {
    if (isLoading === false && state === STATE.ENTERED)
      setHeight(document.querySelector(collapsableId).scrollHeight);
    setCollapsable(document.querySelector(collapsableId));
  }, []);

  useEffect(() => {
    if (collapsable) {
      collapsable.className = classes[state];
      switch (state) {
        case STATE.ENTERING:
          collapsable.style.height = "0px";
          requestAnimationFrame(() => {
            collapsable.style.height = height + "px";
          });
          break;
        case STATE.ENTERED:
          collapsable.style.height = "";
          break;
        case STATE.EXITING:
          collapsable.style.height = height + "px";
          requestAnimationFrame(() => {
            collapsable.style.height = "0px";
          });
          break;
        case STATE.EXITED:
          collapsable.style.height = "";
          break;
      }
      if (state === STATE.ENTERED) setHeight(collapsable.scrollHeight);
    }
  }, [state]);

  return [state, toggle, enter, exit];
}
