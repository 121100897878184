import objectHash from 'object-hash';
import React from 'react';
import {browserHistory, Route, Router, withRouter} from 'react-router';

import Organizations from '../../../components/js/ui/organization/OrganizationsParcel';

function Dig(props) {
  const {
    apiStatus,
    organizations,
    structuredFeedbackTypes,
    organizationSyncsOpen
  } = props;

  const key = objectHash({
    apiStatus,
    organizations: organizations?.map((organization) => {
      return {
        id: organization.id,
        updated: organization.updated_at
      };
    }),
    structuredFeedbackTypes: structuredFeedbackTypes,
    organizationSyncsOpen: organizationSyncsOpen
  });

  return (
    <Router history={browserHistory} key={key}>
      <Route
        path="*"
        component={() => <Organizations profile={props.user} {...props} />}
      />
    </Router>
  );
}

const areEqual = (prevProps, nextProps) => {
  const prevKey = objectHash({
    apiStatus: prevProps.apiStatus,
    organizations: prevProps.organizations?.map((organization) => {
      return {
        id: organization.id,
        updated: organization.updated_at
      };
    }),
    structuredFeedbackTypes: prevProps.structuredFeedbackTypes,
    organizationSyncsOpen: prevProps.organizationSyncsOpen
  });
  const nextKey = objectHash({
    apiStatus: nextProps.apiStatus,
    organizations: nextProps.organizations?.map((organization) => {
      return {
        id: organization.id,
        updated: organization.updated_at
      };
    }),
    structuredFeedbackTypes: nextProps.structuredFeedbackTypes,
    organizationSyncsOpen: nextProps.organizationSyncsOpen
  });

  return prevKey === nextKey;
};

const MemoizedDig = React.memo(Dig, areEqual);

export default withRouter(MemoizedDig);
