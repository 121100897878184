import { asyncify, memoize } from "async";
import axios from "axios";
import clsx from "clsx";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { camelCase, cloneDeep, debounce } from "lodash";
import { observer } from "mobx-react-lite";
import objectHash from "object-hash";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import Parcel from "single-spa-react/parcel";

import { collectUrlSearch, buildUrlSearch } from "../helpers";

import config from "../../../../config";
import { filters, filtersFallback } from "../../../../const";
import useAuthentication from "../../../../hooks/useAuthentication";
import usePrevious from "../../../../hooks/usePrevious";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";

import "../scss/App.scoped.scss";

import OrganizationsCards from "./OrganizationsCards";
import OrganizationsSearch from "./OrganizationsSearch";
import { OrganizationsParcel } from "../../../dig/src/InReachVentures-dig.organizations";
import OrganizationsPagination from "./OrganizationsPagination";

dayjs.extend(customParseFormat);

const debounceWithMemo = (fn, timeout) => {
  let debounced = debounce(fn, timeout);
  debounced.memo = fn.memo;
  return debounced;
};

const hasherParams = (params) => {
  params = new URLSearchParams(params);
  params.delete("access_token");
  params.delete("client");
  const hash = Object.fromEntries(params.entries());
  return objectHash(hash);
};

const hasherURL = (url) => {
  const params = new URLSearchParams(url.search);
  params.delete("access_token");
  params.delete("client");
  const hash = Object.fromEntries(params.entries());
  return objectHash(hash);
};

const axiosGetDebouncedMemoized = debounceWithMemo(
  memoize(
    asyncify(async (url, options) => {
      const result = await axios.get(url, options);
      return result;
    }),
    hasherURL
  ),
  200
);

const persistedDebounce = debounce((f, ...args) => {
  f(...args);
}, 2000);

const debouncedReducer = (state, action) => {
  const { type, value } = action;
  const newState = cloneDeep(state);
  newState[type] = value;
  return newState;
};

const customReducer = (prevState, action) => {
  const { type, value } = action;
  let newState = Array.from(prevState);
  switch (type) {
    case "set":
      return value;
    case "add":
      return [...prevState, value];
    case "remove":
      newState = prevState.filter((elem, index) => value !== index);
      return newState;
    case "update_key":
      newState[value.index][0] = value.value;
      return newState;
    case "update_value":
      newState[value.index][1] = value.value;
      return newState;
    default:
      return prevState;
  }
};

const customInit = () => {
  const params = new URLSearchParams(window.location.search);
  return Array.from(params.entries());
};

function App(props) {
  const { auth0, inboxState, organizationsState, mountParcel, userState } =
    props;

  const location = useLocation();
  const history = useHistory();

  const { AuthenticationLoader, collectToken } = useAuthentication(
    auth0,
    userState
  );

  const [mounted, setMounted] = useState(false);
  const [preventNameDebounce, setPreventNameDebounce] = useState(false);
  const [navVisible, setNavVisible] = useState(true);
  const [structuredFeedbackTypes, setStructuredFeedbackTypes] = useState({});
  const [organizationSyncsOpen, setOrganizationSyncsOpen] = useState({});
  const [debouncedState, debouncedDispatch] = useReducer(debouncedReducer, {
    name: organizationsState.name,
    createdAtGT: organizationsState.createdAtGT,
    createdAtLT: organizationsState.createdAtLT,
    enteredStageGT: organizationsState.enteredStageGT,
    enteredStageLT: organizationsState.enteredStageLT,
    foundedOnGT: organizationsState.foundedOnGT,
    foundedOnLT: organizationsState.foundedOnLT,
    investmentAmountGT: organizationsState.investmentAmountGT,
    investmentAmountLT: organizationsState.investmentAmountLT,
    locationCity: organizationsState.locationCity,
    trafficBetaGT: organizationsState.trafficBetaGT,
    trafficMagnitudeGT: organizationsState.trafficMagnitudeGT,
  });
  const [customState, customDispatch] = useReducer(
    customReducer,
    [],
    customInit
  );

  function handleOpenStructuredFeedback(type, organizationId) {
    const newTypes = Object.assign({}, structuredFeedbackTypes);
    newTypes[organizationId] = type;
    setStructuredFeedbackTypes(newTypes);
  }

  function handleCloseStructuredFeedback() {
    setStructuredFeedbackTypes({});
  }

  function handleOpenWorkflowSync(organizationId) {
    const newOpens = Object.assign({}, organizationSyncsOpen);
    newOpens[organizationId] = true;
    setOrganizationSyncsOpen(newOpens);
  }

  function handleCloseWorkflowSync() {
    setOrganizationSyncsOpen({});
  }

  const topEl = useRef(null);
  const previousOffset = usePrevious(organizationsState.offset);
  const { width, height } = useWindowDimensions();

  useEffect(() => {
    window.addEventListener(
      "nav_visible",
      (e) => {
        setNavVisible(e.detail);
      },
      false
    );
  }, []);

  const apiUrl = new URL(config.api.host + "/organizations");

  const scrollToHash = () => {
    if (!document.querySelector(location.hash)) {
      window.requestAnimationFrame(scrollToHash);
    } else {
      window.scrollTo(0, document.querySelector(location.hash).offsetTop);
    }
  };

  const scrollToTop = () => {
    if (topEl?.current) {
      while (topEl.current.scrollHeight === 0) {
        window.requestAnimationFrame(scrollToTop);
      }
      window.scrollTo(0, topEl.current.offsetTop);
    }
  };

  const updateCustom = (params) => {
    const filteredCustoms = ["offset", "limit"];
    const newCustom = params.filter(
      ([key, value], i) => !filteredCustoms.includes(key)
    );
    customDispatch({ type: "set", value: newCustom });
  };

  const organizationsTriggerCustom = (params) => {
    organizationsState.setOrganizations(null);
    params = Object.assign(
      {
        access_token: userState.token,
        client: userState.client,
        limit: organizationsState.size,
        offset: organizationsState.offset,
        custom: true,
      },
      Object.fromEntries(params)
    );
    collectOrganizations(params);
    collectUrlSearch(params, organizationsState);
    if (previousOffset !== organizationsState.offset) {
      scrollToTop();
    }
  };

  const updateSearch = (params) => {
    delete params.access_token;
    delete params.client;
    history.replace(`${location.pathname}?${new URLSearchParams(params)}`, {});
  };

  const collectOrganizations = (params) => {
    apiUrl.search = new URLSearchParams(params).toString();
    updateSearch(params);
    updateCustom(Object.entries(params));
    axiosGetDebouncedMemoized(
      apiUrl,
      {
        timeout: 10000,
        headers: {
          Authorization: "Bearer " + userState.token,
        },
      },
      (e, response) => {
        if (e !== null) {
          organizationsState.setApiStatus(e?.response?.status || "timeout");
          organizationsState.setTotal(0);
          console.error(e.response);
        } else {
          organizationsState.setApiStatus(200);
          organizationsState.setOrganizations(
            response.data.organizations || []
          );
          organizationsState.setScores(response.data.scores || null);
          organizationsState.setTotal(response.data.total);
        }
      }
    );
  };

  const handleOrganizationChange = async (
    id,
    operation = "update",
    reason = undefined
  ) => {
    const searchParams = new URLSearchParams({
      access_token: userState.token,
      client: userState.client,
    });
    const url = new URL(config.api.host + "/organizations" + `/${id}`);
    url.search = searchParams.toString();
    const organization = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + userState.token,
      },
    });
    organizationsState.updateOrganization(id, organization.data);
    const params = buildUrlSearch(
      userState.token,
      userState.client,
      organizationsState
    );
    const hash = hasherParams(params);
    delete axiosGetDebouncedMemoized.memo[hash];
    switch (operation) {
      case "add":
        inboxState.setForceUpdate(true);
        inboxState.incrementCounter("inbox");
        break;
      case "remove":
        inboxState.setForceUpdate(true);
        inboxState.decreaseCounter("inbox");
        if (reason !== undefined) inboxState.decreaseCounter(reason);
        break;
      case "update":
      default:
        break;
    }
  };

  const handleResetFilters = () => {
    setPreventNameDebounce(true);
    organizationsState.setOffset(0);
    organizationsState.clearSets();
    organizationsState.setCurrentWidget("");
    organizationsState.setName("");
    organizationsState.setNameFlag(true);
    Object.entries(filters)
      .filter(([filter, label], i) => !filter.startsWith("separator"))
      .map(([filter, label], i) => {
        organizationsState[camelCase("set_" + filter)](false);
      });
  };

  const handleNavOrganizationsSimilarTo = (organization) => {
    organizationsState.setOrganizations(null);
    organizationsState.setOffset(0);
    handleResetFilters();
    organizationsState.setOrganizationsSimilarTo(true);
    organizationsState.setOrganizationsSimilarToValue(organization.id);
    organizationsState.setOrganizationsSimilarToName(organization.name);
    ("");
    organizationsState.setOrderedBy("score");
    organizationsState.setOrder("desc");
  };

  const flattenArrayParams = (params = {}) =>
    Object.entries(params).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: Array.isArray(v) ? v.join(",") : v,
      }),
      {}
    );

  if (!mounted && location.search) {
    collectUrlSearch(location.search, organizationsState);
  }

  useEffect(() => {
    setMounted(true);
  }, []);

  const organizationsTrigger = (params) => {
    collectOrganizations(params);
    if (location.hash) {
      scrollToHash();
    }
    if (previousOffset !== organizationsState.offset && topEl.current) {
      scrollToTop();
    }
  };

  const retryCollectOrganizations = () => {
    organizationsState.setApiStatus(200);
    if (organizationsState.mode === "custom")
      organizationsTriggerCustom(customState);
    else {
      const params = buildUrlSearch(
        userState.token,
        userState.client,
        organizationsState
      );
      organizationsTrigger(params);
    }
  };

  useEffect(() => {
    if (!preventNameDebounce) {
      persistedDebounce((name) => {
        organizationsState.setOrganizations(null);
        organizationsState.setOffset(0);
        if (name !== "") {
          organizationsState.setOrderedBy("score");
          organizationsState.setCurrentWidget("");
          organizationsState.clearSets();
          Object.entries(filters)
            .filter(([filter, label], i) => !filter.startsWith("separator"))
            .map(([filter, label], i) => {
              if (filter === "classificationNot") {
                organizationsState[camelCase("set_" + filter)](true);
                organizationsState.addClassificationsNot("never");
                organizationsState.addClassificationsNot("missed_opportunity");
              } else {
                organizationsState[camelCase("set_" + filter)](false);
              }
            });
        }
        debouncedDispatch({ type: "name", value: name });
      }, organizationsState.name);
    } else {
      setPreventNameDebounce(false);
    }
  }, [organizationsState.name]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "createdAtGT",
      value: organizationsState.createdAtGT,
    });
  }, [organizationsState.createdAtGT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "createdAtLT",
      value: organizationsState.createdAtLT,
    });
  }, [organizationsState.createdAtLT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "enteredStageGT",
      value: organizationsState.enteredStageGT,
    });
  }, [organizationsState.enteredStageGT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "enteredStageLT",
      value: organizationsState.enteredStageLT,
    });
  }, [organizationsState.enteredStageLT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "foundedOnGT",
      value: organizationsState.foundedOnGT,
    });
  }, [organizationsState.foundedOnGT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "foundedOnLT",
      value: organizationsState.foundedOnLT,
    });
  }, [organizationsState.foundedOnLT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "investmentAmountGT",
      value: organizationsState.investmentAmountGT,
    });
  }, [organizationsState.investmentAmountGT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "investmentAmountLT",
      value: organizationsState.investmentAmountLT,
    });
  }, [organizationsState.investmentAmountLT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "trafficBetaGT",
      value: organizationsState.trafficBetaGT,
    });
  }, [organizationsState.trafficBetaGT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "trafficMagnitudeGT",
      value: organizationsState.trafficMagnitudeGT,
    });
  }, [organizationsState.trafficMagnitudeGT]);

  useEffect(() => {
    persistedDebounce(debouncedDispatch, {
      type: "locationCity",
      value: organizationsState.locationCity,
    });
  }, [organizationsState.locationCity]);

  useEffect(() => {
    if (userState.token && userState.client && userState.defaultFilters) {
      if (organizationsState.mode === "assisted") {
        const params = buildUrlSearch(
          userState.token,
          userState.client,
          organizationsState
        );
        organizationsTrigger(params);
      }
      if (
        organizationsState.mode === "custom" &&
        !organizationsState.firstRun
      ) {
        organizationsTriggerCustom(customState);
      }
      if (organizationsState.firstRun && !location.search) {
        Object.keys(userState.defaultFilters).length
          ? collectUrlSearch(
              userState.defaultFilters[location.pathname],
              organizationsState
            )
          : collectUrlSearch(
              filtersFallback[location.pathname],
              organizationsState
            );
      }
      if (organizationsState.firstRun) {
        organizationsState.setFirstRun(false);
      }
    }
  }, [
    organizationsState.nameFlag,
    organizationsState.mode,
    organizationsState.offset,
    organizationsState.orderedBy,
    organizationsState.order,
    organizationsState.keywords,
    organizationsState.termsOperator,
    organizationsState.termsArray,
    organizationsState.foundedOn,
    organizationsState.enteredStage,
    organizationsState.createdAt,
    organizationsState.traffic,
    organizationsState.classification,
    organizationsState.classificationsArray,
    organizationsState.classificationNot,
    organizationsState.classificationsNotArray,
    organizationsState.trafficClassification,
    organizationsState.trafficClassificationsArray,
    organizationsState.hasPredictions,
    organizationsState.hasPredictionsValue,
    organizationsState.hasMissingData,
    organizationsState.hasMissingDataValue,
    organizationsState.investmentAmount,
    organizationsState.investmentAmountDaysGT,
    organizationsState.investmentAmountDaysLT,
    organizationsState.investmentType,
    organizationsState.investmentTypesArray,
    organizationsState.isClosed,
    organizationsState.isClosedValue,
    organizationsState.isComplete,
    organizationsState.isCompleteValue,
    organizationsState.isInInbox,
    organizationsState.isInInboxValue,
    organizationsState.isSpam,
    organizationsState.isSpamValue,
    organizationsState.isZombie,
    organizationsState.isZombieValue,
    organizationsState.requiresMoreInfo,
    organizationsState.requiresMoreInfoValue,
    organizationsState.requiresManualHelp,
    organizationsState.requiresManualHelpValue,
    organizationsState.makeContactRequiresAction,
    organizationsState.makeContactRequiresActionValue,
    organizationsState.unableToContactRequiresAction,
    organizationsState.unableToContactRequiresActionValue,
    organizationsState.source,
    organizationsState.sourceValue,
    organizationsState.stage,
    organizationsState.stageValue,
    organizationsState.location,
    organizationsState.locationContinent,
    organizationsState.locationCountry,
    organizationsState.statePartner,
    organizationsState.statePartnerValue,
    organizationsState.stateUser,
    organizationsState.stateUserValue,
    organizationsState.stateUserUser,
    organizationsState.previousExits,
    organizationsState.previousExitsValue,
    organizationsState.daysTopTech,
    organizationsState.daysTopTechValue,
    organizationsState.assignedTo,
    organizationsState.assignedToValue,
    organizationsState.organizationsSimilarTo,
    organizationsState.organizationsSimilarToValue,
    debouncedState,
    userState.defaultFilters,
    userState.token,
    userState.client,
  ]);

  const searchParams = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );

  if (userState.isLoading || !mounted) {
    return <AuthenticationLoader />;
  } else {
    return userState.isAuthenticated &&
      userState.defaultFilters &&
      userState.roles.length &&
      userState.token &&
      userState.user &&
      (location.pathname === "/organizations" ||
        userState.accessType === "full") ? (
      <div class="row">
        <div class="col-lg-4">
          <div
            class={clsx(
              "organization-search-container",
              !navVisible && width > 992 && "stuck",
              width < 992 && "mb-3"
            )}
          >
            <OrganizationsSearch
              collectToken={collectToken}
              customDispatch={customDispatch}
              customState={customState}
              handleResetFilters={handleResetFilters}
              organizationsState={organizationsState}
              organizationsTriggerCustom={organizationsTriggerCustom}
              navVisible={navVisible}
              userState={userState}
            />
          </div>
        </div>
        <div class="col-lg-8" ref={topEl}>
          <Parcel
            config={OrganizationsParcel}
            mountParcel={mountParcel}
            handleError={(err) => console.error(err)}
            apiStatus={organizationsState.apiStatus}
            handleOrganizationChange={handleOrganizationChange}
            handleNavOrganizationsSimilarTo={handleNavOrganizationsSimilarTo}
            organizations={organizationsState.organizationsArray}
            pathname={location.pathname}
            searchParams={searchParams}
            filterSource={{
              route: location.pathname.replace("/", ""),
              params: flattenArrayParams(searchParams),
            }}
            userHasFullAccess={userState.accessType === "full"}
            userRoles={userState.roles}
            token={userState.token}
            user={userState.user}
            client={userState.client}
            retryCollectOrganizations={retryCollectOrganizations}
            visualizeScore={organizationsState.organizationsSimilarTo}
            scores={organizationsState.scoresObject}
            openStructuredFeedback={handleOpenStructuredFeedback}
            closeStructuredFeedback={handleCloseStructuredFeedback}
            structuredFeedbackTypes={structuredFeedbackTypes}
            openWorkflowSync={handleOpenWorkflowSync}
            closeWorkflowSync={handleCloseWorkflowSync}
            organizationSyncsOpen={organizationSyncsOpen}
          />
          <OrganizationsPagination organizationsState={organizationsState} />
        </div>
      </div>
    ) : (
      <div class="d-flex flex-column justify-content-center align-items-center">
        <strong>Checking your permissions...</strong>
      </div>
    );
  }
}

export default observer(App);
