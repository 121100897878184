import IconButton from "@material-ui/core/IconButton";
import Link from "@material-ui/core/Link";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

import crunchBase from "../../static/crunchBase_logo.svg";

const useStyles = makeStyles((theme) => ({
  link: {
    lineHeight: "24px !important",
  },
}));

export default function OrganizationLinks(props) {
  const { crunchbase, linkedin, name, twitter, homepage_url, id } = props;

  const classes = useStyles();

  return (
    <div class="d-flex flex-row justify-content-end">
      <IconButton>
        <Link
          className={classes.link}
          href={twitter ? twitter : "https://twitter.com/search?q=" + name}
          target="_blank"
        >
          <i class="bi bi-twitter"></i>
        </Link>
      </IconButton>
      <IconButton>
        <Link
          className={classes.link}
          href={
            linkedin
              ? linkedin
              : "https://www.linkedin.com/search/results/companies/?keywords=" +
                name
          }
        >
          <i class="bi bi-linkedin"></i>
        </Link>
      </IconButton>
      {crunchbase && (
        <IconButton>
          <Link href={crunchbase} className={classes.link}>
            <img src={crunchBase} widht="24" height="24" alt="" />
          </Link>
        </IconButton>
      )}
      {homepage_url && (
        <IconButton>
          <Link href={homepage_url} className={classes.link}>
            <i class="bi-globe"></i>
          </Link>
        </IconButton>
      )}
      <IconButton>
        <Link href={"/organizations/" + id + "/edit"} className={classes.link}>
          <i class="bi-pencil-square"></i>
        </Link>
      </IconButton>
    </div>
  );
}
