import React, { useEffect } from "react";

function AuthenticationLoader(props) {
  return (
    <div class="d-flex justify-content-center my-5">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  );
}

export default function useAuthentication(auth0, userState) {
  // const targetUrl = window.location?.state?.targetUrl || "/";

  const getIsAuthenticated = async () => {
    try {
      userState.setIsAuthenticated(await auth0.isAuthenticated());
    } catch (e) {
      console.error(e);
    }
  };

  const collectToken = async (forceRefresh = false) => {
    try {
      if (forceRefresh) {
        userState.setToken(await auth0.getTokenSilently({ ignoreCache: true }));
      } else {
        userState.setToken(await auth0.getTokenSilently());
      }
    } catch (e) {
      console.error(e);
    }
  };

  const collectUser = async () => {
    try {
      userState.setUser(await auth0.getUser());
    } catch (e) {
      console.error(e);
    }
  };

  const logOut = () => {
    localStorage.removeItem("auth0");
    localStorage.removeItem("googleOAuth");
    auth0.logout({
      returnTo: `${window.location.protocol}//${window.location.host}`,
    });
  };

  if (userState.isLoading) {
    (async () => {
      await getIsAuthenticated();
    })();
  }

  useEffect(() => {
    (async () => {
      await collectToken();
      userState.setIsLoading(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      if (userState.token) await collectUser();
    })();
  }, [userState.token]);

  useEffect(() => {
    if (userState.user) {
      const {
        "https://dig.inreachventures.com/metadata": {
          client: c,
          clients: {
            [c]: { access_type: aT, inboxes: i = [], roles: r },
          },
          filters_sets: fS,
          filters: f,
          user_id: uI,
        },
      } = userState.user;
      userState.setAccessType(aT);
      userState.setClient(c);
      userState.setDefaultFilters(f);
      userState.setFiltersSets(fS);
      userState.setInboxes(i);
      userState.setRoles(r);
      userState.setUserId(uI);
    }
  }, [userState.user]);

  return {
    AuthenticationLoader,
    collectToken,
    logOut,
  };
}
