import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import useAuthentication from "../hooks/useAuthentication";

function AppGuard(props) {
  const { app, auth0, freeEntry, userState } = props;

  const { AuthenticationLoader } = useAuthentication(auth0, userState);

  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (userState.isLoading || !mounted) {
    return <AuthenticationLoader />;
  } else {
    return userState.isAuthenticated &&
      userState.defaultFilters &&
      userState.roles.length &&
      userState.token &&
      userState.user &&
      (freeEntry || userState.accessType === "full") ? (
      app
    ) : (
      <div class="d-flex flex-column justify-content-center align-items-center">
        <strong>Sorry, you have no permissions to visit this page.</strong>
      </div>
    );
  }
}

export default observer(AppGuard);
