import { asyncify, memoize } from "async";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { debounce } from "lodash";
import { observer } from "mobx-react-lite";
import objectHash from "object-hash";
import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import Parcel from "single-spa-react/parcel";

import config from "../../../../config";
import useAuthentication from "../../../../hooks/useAuthentication";
import usePrevious from "../../../../hooks/usePrevious";

import "../scss/App.scoped.scss";

import OrganizationsCards from "./OrganizationsCards";
import OrganizationsSearchFixed from "./OrganizationsSearchFixed";
import { OrganizationsParcel } from "../../../dig/src/InReachVentures-dig.organizations";
import OrganizationsPagination from "./OrganizationsPagination";

dayjs.extend(customParseFormat);

const axiosGetDebouncedMemoized = debounce(
  memoize(
    asyncify(async (url, options) => {
      const result = await axios.get(url, options);
      return result;
    }),
    (url) => {
      const params = new URLSearchParams(url.search);
      params.delete("access_token");
      params.delete("client");
      const hash = Object.fromEntries(params.entries());
      return objectHash(hash);
    }
  ),
  200
);

const pageFilters = {
  order_by: "ml_features_score",
  based_in_continent: "Europe",
  classification_not: "never,missed_opportunity",
  is_closed: false,
  has_missing_data: true,
  ml_features_score_gt: 0.4,
  state: "unseen",
  user_state_not: "never",
};

function Incomplete(props) {
  const { auth0, organizationsState, mountParcel, userState } = props;

  const location = useLocation();

  const {
    isLoading,
    isAuthenticated,
    token,
    accessType,
    AuthenticationLoader,
    user,
    roles,
    filtersSets,
    defaultFilters,
    collectToken,
    userId,
    client,
  } = useAuthentication(auth0);

  const [mounted, setMounted] = useState(false);
  const previousOffset = usePrevious(organizationsState.offset);
  const topEl = useRef(null);

  const apiUrl = new URL(config.api.host + "/organizations");

  const scrollToHash = () => {
    if (!document.querySelector(location.hash)) {
      window.requestAnimationFrame(scrollToHash);
    } else {
      window.scrollTo(0, document.querySelector(location.hash).offsetTop);
    }
  };

  const scrollToTop = () => {
    if (!topEl.current) {
      window.requestAnimationFrame(scrollToTop);
    } else {
      window.scrollTo(0, topEl.current.offsetTop);
    }
  };

  const organizationsTriggerCustom = (params) => {
    organizationsState.setOrganizations(null);
    params = Object.assign(
      {
        access_token: token,
        client,
        limit: organizationsState.size,
        offset: organizationsState.offset,
      },
      params
    );
    collectOrganizations(params);
    if (location.hash) {
      scrollToHash();
    }
    if (previousOffset !== organizationsState.offset) {
      scrollToTop();
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  const collectOrganizations = (params) => {
    apiUrl.search = new URLSearchParams(params).toString();
    axiosGetDebouncedMemoized(
      apiUrl,
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
      (e, response) => {
        if (e !== null) {
          organizationsState.setApiStatus(e.response.status);
          organizationsState.setTotal(0);
          console.error(e.response);
        } else {
          organizationsState.setApiStatus(200);
          organizationsState.setOrganizations(response.data.organizations);
          organizationsState.setTotal(response.data.total);
        }
      }
    );
  };

  const handleOrganizationChange = async (id) => {
    const searchParams = new URLSearchParams({
      access_token: token,
      client: client,
    });
    const url = new URL(apiUrl + `/${id}`);
    url.search = searchParams.toString();
    const organization = await axios.get(url, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    organizationsState.updateOrganization(id, organization.data);
  };

  const flattenArrayParams = (params = {}) =>
    Object.entries(params).reduce(
      (acc, [k, v]) => ({
        ...acc,
        [k]: Array.isArray(v) ? v.join(",") : v,
      }),
      {}
    );

  useEffect(() => {
    if (token && client && defaultFilters) {
      organizationsTriggerCustom(pageFilters);
      if (organizationsState.firstRun) {
        organizationsState.setFirstRun(false);
      }
    }
  }, [
    defaultFilters,
    organizationsState.nameFlag,
    organizationsState.mode,
    organizationsState.offset,
    organizationsState.orderedBy,
    organizationsState.order,
    organizationsState.keywords,
    organizationsState.termsOperator,
    organizationsState.termsArray,
    organizationsState.foundedOn,
    organizationsState.traffic,
    organizationsState.classification,
    organizationsState.classificationsArray,
    organizationsState.classificationNot,
    organizationsState.classificationsNotArray,
    organizationsState.hasPredictions,
    organizationsState.hasPredictionsValue,
    organizationsState.hasMissingData,
    organizationsState.hasMissingDataValue,
    organizationsState.investmentAmount,
    organizationsState.investmentType,
    organizationsState.investmentTypesArray,
    organizationsState.isClosed,
    organizationsState.isClosedValue,
    organizationsState.isComplete,
    organizationsState.isCompleteValue,
    organizationsState.isInInbox,
    organizationsState.isInInboxValue,
    organizationsState.isSpam,
    organizationsState.isSpamValue,
    organizationsState.isZombie,
    organizationsState.isZombieValue,
    organizationsState.requiresMoreInfo,
    organizationsState.requiresMoreInfoValue,
    organizationsState.requiresManualHelp,
    organizationsState.requiresManualHelpValue,
    organizationsState.source,
    organizationsState.sourceValue,
    organizationsState.stage,
    organizationsState.stageValue,
    organizationsState.location,
    organizationsState.locationContinent,
    organizationsState.locationCountry,
    organizationsState.statePartner,
    organizationsState.statePartnerValue,
    organizationsState.stateUser,
    organizationsState.stateUserValue,
    organizationsState.stateUserUser,
    token,
    client,
  ]);

  useEffect(() => {
    userState.setAccessType(accessType);
    userState.setClient(client);
    userState.setFiltersSets(filtersSets);
    userState.setDefaultFilters(defaultFilters);
    userState.setRoles(roles);
    userState.setToken(token);
    userState.setUserId(userId);
  }, [accessType, client, defaultFilters, filtersSets, roles, token, userId]);

  const searchParams = Object.fromEntries(
    new URLSearchParams(location.search).entries()
  );

  if (isLoading || !mounted) {
    return <AuthenticationLoader />;
  } else {
    return isAuthenticated &&
      defaultFilters &&
      roles.length &&
      token &&
      user &&
      (location.pathname === "/organizations" || accessType === "full") ? (
      <div class="row">
        <div class="col-lg-4">
          <div class="organization-search-container">
            <OrganizationsSearchFixed
              collectToken={collectToken}
              organizationsState={organizationsState}
              organizationsTriggerCustom={organizationsTriggerCustom}
              pageFilters={pageFilters}
              userState={userState}
            />
          </div>
        </div>
        <div class="col-lg-8">
          <Parcel
            ref={topEl}
            config={OrganizationsParcel}
            mountParcel={mountParcel}
            handleError={(err) => console.error(err)}
            apiStatus={organizationsState.apiStatus}
            handleOrganizationChange={handleOrganizationChange}
            organizations={organizationsState.organizationsArray}
            pathname={location.pathname}
            profile={user}
            searchParams={searchParams}
            filterSource={{
              route: location.pathname.replace("/", ""),
              params: flattenArrayParams(searchParams),
            }}
            token={token}
            userHasFullAccess={accessType === "full"}
            userRoles={roles}
            source={location.pathname.replace("/", "")}
            structuredFeedbackTypes={{}}
            openStructuredFeedback={() => {}}
            closeStructuedFeedback={() => {}}
            openWorkflowSync={() => {}}
            closeWorkflowSync={() => {}}
            organizationSyncsOpen={{}}
          />
        </div>
        <div class="col-lg-12">
          <OrganizationsPagination organizationsState={organizationsState} />
        </div>
      </div>
    ) : (
      <div class="d-flex flex-column justify-content-center align-items-center">
        <strong>Checking your permissions...</strong>
      </div>
    );
  }
}

export default observer(Incomplete);
