import clsx from "clsx";
import { observer } from "mobx-react-lite";
import React, { useState } from "react";

import "../../scss/Keywords.scoped.scss";

function Keywords(props) {
  const { organizationsState } = props;
  const [currentKeyword, setCurrentKeyword] = useState("");

  const addKeywordFilter = (value) => {
    organizationsState.setOrganizations(null);
    organizationsState.addTerms(value);
    organizationsState.setOffset(0);
    setCurrentKeyword("");
  };

  const removeKeywordFilter = (value) => {
    organizationsState.setOrganizations(null);
    organizationsState.removeTerms(value);
    organizationsState.setOffset(0);
  };

  const setTermsOperator = (value) => {
    organizationsState.setOrganizations(null);
    organizationsState.removeTerms(value);
    organizationsState.setTermsOperator(value);
  };

  return (
    <div class="g-0 rounded border p-1" style={{ background: "#fff" }}>
      <div class="btn-group mb-1 btn-group-sm mx-auto">
        <button
          type="button"
          className={clsx("btn btn-outline-secondary")}
          disabled={organizationsState.termsOperator === "and"}
          onClick={() => {
            setTermsOperator("and");
          }}
        >
          And
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          disabled={organizationsState.termsOperator === "or"}
          onClick={() => {
            setTermsOperator("or");
          }}
        >
          Or
        </button>
      </div>
      <div class="input-group input-group-sm">
        <input
          class="form-control"
          type="text"
          placeholder="Enter to add"
          id="keywords-input"
          value={currentKeyword}
          onInput={(event) => {
            event.preventDefault();
            setCurrentKeyword(event.target.value);
          }}
          onKeyUp={(event) => {
            event.preventDefault();
            if (event.key === "Enter") {
              addKeywordFilter(event.target.value);
            }
          }}
        />
        <button
          class="btn btn-outline-secondary"
          type="button"
          disabled={!currentKeyword}
          onClick={(event) => {
            event.preventDefault();
            if (currentKeyword !== "") {
              addKeywordFilter(currentKeyword);
            }
          }}
        >
          <i class="bi bi-plus"></i>
        </button>
      </div>
      <div class="d-flex">
        {Array.from(organizationsState.terms).map((term) => {
          return (
            <button
              type="button"
              class="btn btn-outline-secondary btn-sm my-1 me-1 organization-keyword-badge"
              onClick={(event) => {
                removeKeywordFilter(term);
              }}
            >
              {term}
              <span class="badge bg-secondary ms-1">
                <i class="bi bi-x"></i>
              </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}

export default observer(Keywords);
