import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { camelCase } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

dayjs.extend(customParseFormat);

function FromTo(props) {
  const { filter, label, organizationsState } = props;

  const isNMonthsAgo = (ago) => {
    const nMonthsAgo = dayjs().subtract(ago, "months");
    return (
      nMonthsAgo.format("YYYY-MM") ===
      organizationsState[camelCase(filter + "_G_T")].format("YYYY-MM")
    );
  };

  const isCurrentMonth = () => {
    const nMonthsAgo = dayjs().add(1, "months");
    return (
      nMonthsAgo.format("YYYY-MM") ===
      organizationsState[camelCase(filter + "_L_T")].format("YYYY-MM")
    );
  };

  return (
    <div class="g-0 rounded border p-1" style={{ background: "#fff" }}>
      <div class="input-group input-group-sm pb-1">
        <span class="input-group-text">From</span>
        <input
          value={organizationsState[camelCase(filter + "_G_T")].format(
            "YYYY-MM"
          )}
          type="month"
          class="form-control"
          onInput={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState[camelCase("set_" + filter + "_G_T")](
              dayjs(event.target.value, "YYYY-MM")
            );
          }}
        />
        <button
          class="btn btn-outline-secondary"
          disabled={isNMonthsAgo(12)}
          onClick={() => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState[camelCase("set_" + filter + "_G_T")](
              dayjs().startOf("month").subtract(12, "months")
            );
          }}
          type="button"
        >
          12M
        </button>
        <button
          class="btn btn-outline-secondary"
          disabled={isNMonthsAgo(18)}
          onClick={() => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState[camelCase("set_" + filter + "_G_T")](
              dayjs().startOf("month").subtract(18, "months")
            );
          }}
          type="button"
        >
          18M
        </button>
        <button
          class="btn btn-outline-secondary"
          disabled={isNMonthsAgo(24)}
          onClick={() => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState[camelCase("set_" + filter + "_G_T")](
              dayjs().startOf("month").subtract(24, "months")
            );
          }}
          type="button"
        >
          24M
        </button>
      </div>
      <div class="input-group input-group-sm">
        <span class="input-group-text">To</span>
        <input
          value={organizationsState[camelCase(filter + "_L_T")].format(
            "YYYY-MM"
          )}
          type="month"
          class="form-control"
          placeholder="e.g. now"
          onInput={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState[camelCase("set_" + filter + "_L_T")](
              dayjs(event.target.value, "YYYY-MM")
            );
          }}
        />
        <button
          class="btn btn-outline-secondary"
          disabled={isCurrentMonth()}
          type="button"
          onClick={() => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState[camelCase("set_" + filter + "_L_T")](
              dayjs().startOf("month").add(1, "months")
            );
          }}
        >
          Now
        </button>
      </div>
    </div>
  );
}

export default observer(FromTo);
