import { observer } from "mobx-react-lite";
import React from "react";

function DaysTopTech(props) {
  const { organizationsState } = props;

  const setDaysTopTech = (value) => {
    organizationsState.setOrganizations(null);
    organizationsState.setDaysTopTechValue(value);
    organizationsState.setOffset(0);
  };

  return (
    <div class="row g-0 rounded border" style={{ background: "#fff" }}>
      <div class="input-group input-group-sm pb-1">
        <span class="input-group-text">At least</span>
        <input
          value={organizationsState.daysTopTechValue}
          type="number"
          class="form-control"
          min="180"
          step="60"
          onInput={(event) => {
            setDaysTopTech(event.target.value);
          }}
        />
        <span class="input-group-text">days</span>
        <button
          class="btn btn-outline-secondary"
          type="button"
          disabled={organizationsState.daysTopTechValue === 180}
          onClick={(event) => {
            setDaysTopTech(180);
          }}
        >
          6+M
        </button>
        <button
          class="btn btn-outline-secondary"
          disabled={organizationsState.daysTopTechValue === 360}
          type="button"
          onClick={(event) => {
            setDaysTopTech(360);
          }}
        >
          1+Y
        </button>
        <button
          class="btn btn-outline-secondary"
          type="button"
          disabled={organizationsState.daysTopTechValue === 540}
          onClick={(event) => {
            setDaysTopTech(540);
          }}
        >
          1.5+Y
        </button>
      </div>
    </div>
  );
}

export default observer(DaysTopTech);
