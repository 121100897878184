import { observer } from "mobx-react-lite";
import React from "react";

import { investmentTypesFilter } from "../../../../../const";

import "../../scss/InvestmentType.scoped.scss";

function InvestmentType(props) {
  const { organizationsState } = props;

  return (
    <div class="g-0 rounded border p-1" style={{ background: "#fff" }}>
      {Object.entries(investmentTypesFilter).map(([type, label]) => {
        return (
          <div class="form-check form-check-inline">
            <input
              class="form-check-input"
              type="checkbox"
              id={"investment-type-" + type}
              checked={organizationsState.investmentTypes.has(type)}
              onInput={(event) => {
                if (organizationsState.investmentTypes.has(type)) {
                  organizationsState.setOrganizations(null);
                  organizationsState.setOffset(0);
                  organizationsState.removeInvestmentTypes(type);
                } else {
                  organizationsState.setOrganizations(null);
                  organizationsState.setOffset(0);
                  organizationsState.addInvestmentTypes(type);
                }
              }}
            />
            <label class="form-check-label" for={"investment-type-" + type}>
              {label}
            </label>
          </div>
        );
      })}
      <div class="form-check form-check-inline">
        <input
          disabled
          class="form-check-input"
          type="checkbox"
          id={"investment-type-all"}
          checked={organizationsState.investmentTypeAll(
            Object.keys(investmentTypesFilter)
          )}
        />
        <label class="form-check-label" for={"investment-type-all"}>
          All
        </label>
      </div>
    </div>
  );
}

export default observer(InvestmentType);
