import { observer } from "mobx-react-lite";
import React from "react";

import sources from "../../../../../components/js/content/sources";

function Source(props) {
  const { organizationsState, filter, label } = props;

  return (
    <div class="g-0 rounded border p-1" style={{ background: "#fff" }}>
      <select
        class="form-select form-select-sm"
        onChange={(event) => {
          organizationsState.setOrganizations(null);
          organizationsState.setOffset(0);
          organizationsState.setSourceValue(event.target.value);
        }}
      >
        <option selected={organizationsState.sourceValue === ""} value="">
          All
        </option>
        {sources.map((source) => (
          <option
            selected={organizationsState.sourceValue === source.value}
            value={source.value}
          >
            {source.label}
          </option>
        ))}
      </select>
    </div>
  );
}

export default observer(Source);
