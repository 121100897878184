import { camelCase } from "lodash";
import { observer } from "mobx-react-lite";
import React from "react";

import "../../scss/OnOff.scoped.scss";

function OnOff(props) {
  const { organizationsState, filter, label } = props;

  return (
    <div class="g-0 rounded border p-1" style={{ background: "#fff" }}>
      <div class="form-check form-check-inline p-0">
        <input
          // ref={refs[i]}
          class="form-check-input m-1"
          type="checkbox"
          id={filter}
          checked={organizationsState[camelCase(filter + "_value")]}
          onInput={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            if (organizationsState[camelCase(filter + "_value")] === true) {
              organizationsState[camelCase("set_" + filter + "_value")](false);
            } else {
              organizationsState[camelCase("set_" + filter + "_value")](true);
            }
          }}
        />
        <label class="form-check-label" for={filter}>
          Include
        </label>
      </div>
    </div>
  );
}

export default observer(OnOff);
