import React from "react";

export default function OrganizationFounded(props) {
  const { foundedOn } = props;

  const foundedOnString = new Date(foundedOn).toLocaleDateString("en-UK", {
    year: "numeric",
    month: "short",
  });

  return (
    <span>
      <i>{foundedOnString}</i>
    </span>
  );
}
