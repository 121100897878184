import { observer } from "mobx-react-lite";
import React from "react";

import { trafficClassifications } from "../../../../../const";

import "../../scss/Classification.scoped.scss";

function TrafficClassification(props) {
  const { organizationsState } = props;

  return (
    <div class="rounded border p-1" style={{ background: "#fff" }}>
      {Object.entries(trafficClassifications).map(([type, label]) => {
        return (
          <div class="form-check form-check-inline p-0">
            <input
              class="form-check-input m-1"
              type="checkbox"
              id={"classification-" + type}
              checked={organizationsState.trafficClassifications.has(type)}
              onInput={(event) => {
                if (organizationsState.trafficClassifications.has(type)) {
                  organizationsState.setOrganizations(null);
                  organizationsState.setOffset(0);
                  organizationsState.removeTrafficClassification(type);
                } else {
                  organizationsState.setOrganizations(null);
                  organizationsState.setOffset(0);
                  organizationsState.addTrafficClassifications(type);
                }
              }}
            />
            <label class="form-check-label" for={"classification-" + type}>
              {label}
            </label>
          </div>
        );
      })}
      <div class="form-check form-check-inline p-0">
        <input
          disabled
          class="form-check-input m-1"
          type="checkbox"
          id={"investment-type-all"}
          checked={organizationsState.trafficClassificationAll(
            Object.keys(trafficClassifications)
          )}
        />
        <label class="form-check-label" for={"investment-type-all"}>
          All
        </label>
      </div>
    </div>
  );
}

export default observer(TrafficClassification);
