import { observer } from "mobx-react-lite";
import React from "react";

import stages from "../../../../../components/js/config/streak_stages";

function Stage(props) {
  const { organizationsState, filter, label } = props;

  return (
    <div class="g-0 rounded border p-1" style={{ background: "#fff" }}>
      <select
        class="form-select form-select-sm"
        onChange={(event) => {
          organizationsState.setOrganizations(null);
          organizationsState.setOffset(0);
          organizationsState.setStageValue(event.target.value);
        }}
      >
        <option selected={organizationsState.stageValue === ""} value="">
          All
        </option>
        {stages.map((stage) => (
          <option
            selected={organizationsState.stageValue === stage.key}
            value={stage.key}
          >
            {stage.name}
          </option>
        ))}
      </select>
    </div>
  );
}

export default observer(Stage);
