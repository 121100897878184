export default {
  web: {
    host: process.env.WEB_HOST,
  },
  api: {
    host: process.env.API_HOST,
  },
  worker: {
    host: process.env.WORKER_HOST,
  },
  auth0: {
    url: process.env.AUTH0_URL,
    clientId: process.env.AUTH0_CLIENT_ID,
    sso: !process.env.AUTH0_DISABLE_SSO,
    dangerouslyEnableImpersonation: process.env.NODE_ENV !== "production",
  },
  clientsConfigURL: process.env.CLIENTS_CONFIG_URL,
  form: {
    version: process.env.FORM_VERSION || "V2",
  },
  sentryDsn: process.env.SENTRY_DSN,
  jotformApiKey: process.env.JOTFORM_API_KEY,
  structured_feedback: {
    is_enabled: !!process.env.STRUCTURED_FEEDBACK_HOST,
    host: process.env.STRUCTURED_FEEDBACK_HOST,
  },
  workflow_sync: {
    is_enabled: !!process.env.WORKFLOW_SYNC_HOST,
    host: process.env.WORKFLOW_SYNC_HOST,
  },
};
