import { observer } from "mobx-react-lite";
import React from "react";

import users from "../../../../../components/js/config/inreach_team";

//import "../../scss/AssignedTo.scoped.scss";

function AssignedTo(props) {
  const { filter, label, organizationsState } = props;

  const activeUsers = users.filter((user) => !user.exclude);

  return (
    <div class="rounded border p-1" style={{ background: "#fff" }}>
      <div class="input-group input-group-sm">
        <label for="continentSelect" class="input-group-text">
          User
        </label>
        <select
          class="form-select form-select-sm"
          id="userSelect"
          onChange={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState.setAssignedToValue(event.target.value);
          }}
        >
          <option selected={organizationsState.assignedToValue === ""} value="">
            All
          </option>
          {activeUsers
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((user) => (
              <option
                selected={organizationsState.assignedToValue === user.email}
                value={user.email}
              >
                {user.name}
              </option>
            ))}
        </select>
      </div>
    </div>
  );
}

export default observer(AssignedTo);
