import { observer } from "mobx-react-lite";
import React from "react";

import "../scss/OrganizationsCards.scoped.scss";

import OrganizationCard from "./OrganizationCard";
import OrganizationsPagination from "./OrganizationsPagination";

function OrganizationsCards(props) {
  const { organizationsState } = props;

  if (organizationsState.organizations === null) {
    return (
      <div class="d-flex justify-content-center">
        <div class="spinner-border text-primary" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  } else {
    if (organizationsState.organizations !== undefined) {
      if (organizationsState.gridView) {
        return (
          <div>
            <div class="row pb-3">
              {organizationsState.organizations.map((organization) => {
                return (
                  <div class="col-sm-4">
                    <OrganizationCard
                      organization={organization}
                      organizationsState={organizationsState}
                    />
                  </div>
                );
              })}
            </div>
            <div class="pb-3">
              <OrganizationsPagination
                organizationsState={organizationsState}
              />
            </div>
          </div>
        );
      } else {
        return (
          <div>
            <div class="pb-3">
              {organizationsState.organizations.map((organization) => {
                return (
                  <OrganizationCard
                    organization={organization}
                    organizationsState={organizationsState}
                  />
                );
              })}
            </div>
            <div class="pb-3">
              <OrganizationsPagination
                organizationsState={organizationsState}
              />
            </div>
          </div>
        );
      }
    } else {
      return (
        <div class="d-flex flex-column justify-content-center align-items-center">
          Zero results for your research.
        </div>
      );
    }
  }
}

export default observer(OrganizationsCards);
