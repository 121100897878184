import { compactInteger } from "humanize-plus";
import { observer } from "mobx-react-lite";
import React from "react";

import { investmentAmountDaysZones } from "../../../../../const";

function InvestmentAmount(props) {
  const { organizationsState } = props;

  return (
    <div class="row g-0 rounded border p-1" style={{ background: "#fff" }}>
      <div class="input-group input-group-sm pb-1">
        <span class="input-group-text">From</span>
        <span class="input-group-text">
          {compactInteger(organizationsState.investmentAmountGT, 1)}
        </span>
        <input
          value={organizationsState.investmentAmountGT}
          type="number"
          class="form-control"
          min="0"
          step="100000"
          onInput={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setInvestmentAmountGT(event.target.value);
            organizationsState.setOffset(0);
          }}
        />
        <span class="input-group-text">$</span>
      </div>
      <div class="input-group input-group-sm pb-1">
        <span class="input-group-text">To</span>
        <span class="input-group-text">
          {compactInteger(organizationsState.investmentAmountLT, 1)}
        </span>
        <input
          value={organizationsState.investmentAmountLT}
          type="number"
          class="form-control"
          min="0"
          step="100000"
          onInput={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setInvestmentAmountLT(event.target.value);
            organizationsState.setOffset(0);
          }}
        />
        <span class="input-group-text">$</span>
      </div>
      <div class="mb-1 input-group input-group-sm">
        <label for="investmentAmountDaysSelect" class="input-group-text">
          Timespan
        </label>
        <select
          class="form-select form-select-sm"
          id="investmentAmountDaysSelect"
          onChange={(event) => {
            organizationsState.setOrganizations(null);
            organizationsState.setOffset(0);
            organizationsState.setInvestmentAmountDaysGT(
              investmentAmountDaysZones[event.target.value].gt
            );
            organizationsState.setInvestmentAmountDaysLT(
              investmentAmountDaysZones[event.target.value].lt
            );
          }}
        >
          {investmentAmountDaysZones.map((zone, i) => (
            <option
              selected={
                organizationsState.investmentAmountDaysLT ===
                  investmentAmountDaysZones[i].lt &&
                organizationsState.investmentAmountDaysGT ===
                  investmentAmountDaysZones[i].gt
              }
              value={i}
            >
              {zone.label}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default observer(InvestmentAmount);
