import axios from "axios";
import { cloneDeep } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { observer } from "mobx-react-lite";
import React, { useReducer, useState } from "react";

import config from "../../../../../config";
import { filtersFallback } from "../../../../../const";

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: "60%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    top: "50%",
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  },
  tabs: {
    backgroundColor: "white",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} {...other} class="p-5">
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function FiltersSetSave(props) {
  const {
    collectToken,
    isCurrentFiltersInFiltersSets,
    isEqualCurrentFiltersWithDefault,
    userState,
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [name, setName] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [saveAsAlert, setSaveAsAlert] = useState(false);

  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleTabChange = (event, newValue) => {
    if (newValue === 1) setSaveAsAlert(false);
    setTab(newValue);
  };

  const handleInputsChange = (event) => {
    setName(event.target.value);
  };

  const handleCheckboxChange = (event) => {
    setSaveAsAlert(event.target.checked);
  };

  const apiUrl = `${config.api.host}/users/${userState.userId}/user_metadata`;

  const saveFiltersSet = async (event) => {
    event.preventDefault();

    setIsSaving(true);

    const searchParams = new URLSearchParams({
      access_token: userState.token,
      client: userState.client,
    });

    const url = new URL(apiUrl);
    url.search = searchParams.toString();

    const newFilterSets = cloneDeep(userState.filtersSets || {});

    newFilterSets[name] = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    );
    delete newFilterSets[name].limit;
    delete newFilterSets[name].name;
    delete newFilterSets[name].offset;

    if (saveAsAlert) {
      const alertUrl = new URL("https://api.inreachventures.com/alerts");
      alertUrl.search = searchParams.toString();

      const alertResponse = await axios.post(
        alertUrl,
        {
          client: userState.client,
          user_id: userState.user.email,
          title: name,
          type: "ORGANIZATIONS",
          organization_search_params: newFilterSets[name],
        },
        {
          headers: {
            Authorization: "Bearer " + userState.token,
            "Content-Type": "application/json",
          },
        }
      );
    }

    const response = await axios.put(
      url,
      {
        filters: userState.defaultFilters,
        filters_sets: newFilterSets,
      },
      {
        headers: {
          Authorization: "Bearer " + userState.token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      await collectToken(true);
      setIsSaving(false);
      setSaveAsAlert(false);
      setName("");
      handleModalClose();
      forceUpdate();
    }
  };

  const setDefaultFilters = async (event) => {
    event.preventDefault();

    setIsSaving(true);

    const searchParams = new URLSearchParams({
      access_token: userState.token,
      client: userState.client,
    });

    const url = new URL(apiUrl);
    url.search = searchParams.toString();

    let filters = Object.keys(userState.defaultFilters).length
      ? cloneDeep(userState.defaultFilters)
      : cloneDeep(filtersFallback);
    filters[location.pathname] = Object.fromEntries(
      new URLSearchParams(location.search).entries()
    );
    delete filters[location.pathname].limit;
    delete filters[location.pathname].name;
    delete filters[location.pathname].offset;

    const response = await axios.put(
      url,
      {
        filters,
        filter_sets: userState.filtersSets,
      },
      {
        headers: {
          Authorization: "Bearer " + userState.token,
          "Content-Type": "application/json",
        },
      }
    );

    if (response.status === 200) {
      await collectToken(true);
      setIsSaving(false);
    }
  };

  const body = (
    <div className={classes.paper}>
      <AppBar position="static">
        <Tabs
          value={tab}
          classes={classes.tabs}
          onChange={handleTabChange}
          indicatorColor="secondary"
          centered
        >
          <Tab label="New" />
          <Tab
            label="Update Existing"
            disbled={Object.keys(userState.filtersSets).length}
          />
        </Tabs>
      </AppBar>
      <TabPanel value={tab} index={0}>
        <div class="input-group">
          <input
            type="text"
            value={name}
            class="form-control"
            placeholder="Filters set name"
            onChange={handleInputsChange}
          />
          <button
            class="btn btn-outline-primary"
            type="button"
            disabled={!name || isSaving}
            onClick={saveFiltersSet}
          >
            {isSaving ? (
              <span class="spinner-border spinner-border-sm"></span>
            ) : (
              "Save"
            )}
          </button>
        </div>
        <div class="form-check">
          <input
            class="form-check-input"
            type="checkbox"
            value={saveAsAlert}
            id="saveAsAlert"
            onChange={handleCheckboxChange}
          />
          <label class="form-check-label" for="saveAsAlert">
            Save as alert
          </label>
        </div>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <div class="input-group">
          <select
            class="form-select"
            aria-label="Example select with button addon"
            onChange={handleInputsChange}
          >
            <option selected>Choose existing filters set</option>
            {Object.keys(userState.filtersSets).map((filtersSet) => (
              <option value={filtersSet}>{filtersSet}</option>
            ))}
          </select>
          <button
            disabled={!name || isSaving}
            class="btn btn-outline-primary"
            type="button"
            onClick={saveFiltersSet}
          >
            {isSaving ? (
              <span class="spinner-border spinner-border-sm"></span>
            ) : (
              "Update"
            )}
          </button>
        </div>
      </TabPanel>
    </div>
  );

  if (isCurrentFiltersInFiltersSets() && isEqualCurrentFiltersWithDefault()) {
    return null;
  }

  if (isSaving) {
    return (
      <button
        class="mx-1 btn btn-sm btn-outline-secondary"
        type="button"
        disabled
      >
        <span class="spinner-border spinner-border-sm"></span> Loading...
      </button>
    );
  }

  return (
    <div class="mx-1 d-inline-block">
      <div class="btn-group">
        <button
          type="button"
          class="btn btn-outline-secondary dropdown-toggle btn-sm"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          Save
        </button>
        <ul class="dropdown-menu dropdown-menu-sm-end">
          <li>
            <button
              onClick={handleModalOpen}
              disabled={isCurrentFiltersInFiltersSets()}
              class="dropdown-item btn-sm btn btn-link link-dark"
            >
              <span class="bi bi-save"></span> Save as
            </button>
          </li>
          <li>
            <button
              onClick={setDefaultFilters}
              disabled={isEqualCurrentFiltersWithDefault()}
              class="dropdown-item btn-sm btn btn-link link-dark"
            >
              <span class="bi bi-save"></span> Save as default
            </button>
          </li>
        </ul>
      </div>
      <Modal open={open} onClose={handleModalClose}>
        {body}
      </Modal>
    </div>
  );
}

export default observer(FiltersSetSave);
