import { observer } from "mobx-react-lite";
import React from "react";

import "../../scss/FiltersStatus.scoped.scss";

import { filters } from "../../../../../const";
import FilterStatus from "./FilterStatus";

function FiltersStatus(props) {
  const { organizationsState, userState } = props;

  const activeFilters = Object.entries(filters)
    .filter(([filter, label], i) => !filter.startsWith("separator"))
    .filter(([filter]) => organizationsState[filter]);

  return (
    <div class="row" style={{ background: "#fff" }}>
      <div class="col-12">
        <div class="mb-0 filters-description d-flex flex-wrap">
          {activeFilters.map(([filter, label]) => {
            return (
              <FilterStatus
                filter={filter}
                label={label}
                organizationsState={organizationsState}
                userState={userState}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default observer(FiltersStatus);
