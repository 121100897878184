import dayjs from "dayjs";
import { assignIn, camelCase } from "lodash";

import {
  filtersConfiguration,
  organizationsStateDefaults,
} from "../../../../const";

export const collectUrlSearch = (searchParams, organizationsState) => {
  const uSearchParams = new URLSearchParams(searchParams);
  if (uSearchParams.has("custom") && uSearchParams.get("custom") === "true") {
    organizationsState.setMode("custom");
  } else {
    if (uSearchParams.has("name")) {
      organizationsState.setNameFlag(true);
      organizationsState.setName(uSearchParams.get("name"));
    } else if (uSearchParams.has("domain")) {
      organizationsState.setNameFlag(false);
      organizationsState.setName(uSearchParams.get("domain"));
    }

    if (uSearchParams.has("offset")) {
      organizationsState.setOffset(parseInt(uSearchParams.get("offset")));
    }

    if (uSearchParams.has("order_by")) {
      organizationsState.setOrderedBy(uSearchParams.get("order_by"));
    } else {
      organizationsState.setOrderedBy("founded_on");
    }

    if (uSearchParams.has("order_by") || uSearchParams.get("order") !== null) {
      organizationsState.setOrderedBy(uSearchParams.get("order_by"));
      if (uSearchParams.has("order"))
        organizationsState.setOrder(uSearchParams.get("order"));
      else organizationsState.setOrder("desc");
    }

    for (const [key, filters] of Object.entries(filtersConfiguration)) {
      if (filters.some((filter) => uSearchParams.has(filter.param))) {
        organizationsState[camelCase(`set_${key}`)](true);
        for (const filter of filters) {
          if (uSearchParams.has(filter.param)) {
            // console.log(camelCase(`set_${filter.state}`));
            switch (filter.type) {
              case "date":
                organizationsState[camelCase(`set_${filter.state}`)](
                  dayjs(parseInt(uSearchParams.get(filter.param)))
                );
                break;
              case "integer":
                organizationsState[camelCase(`set_${filter.state}`)](
                  parseInt(uSearchParams.get(filter.param))
                );
                break;
              case "float":
                organizationsState[camelCase(`set_${filter.state}`)](
                  parseFloat(uSearchParams.get(filter.param))
                );
                break;
              case "boolean":
                organizationsState[camelCase(`set_${filter.state}`)](
                  uSearchParams.get(filter.param) === "true"
                );
                break;
              case "array":
                const items = uSearchParams.get(filter.param).split(",");
                if (items.length > 0) {
                  items
                    .filter((item) => item !== "")
                    .forEach((item) =>
                      organizationsState[camelCase(`add_${filter.state}`)](item)
                    );
                }
                break;
              default:
                organizationsState[camelCase(`set_${filter.state}`)](
                  uSearchParams.get(filter.param)
                );
            }
          } else {
            organizationsState[camelCase(`set_${filter.state}`)](
              organizationsStateDefaults[camelCase(filter.state)]
            );
          }
        }
      } else {
        organizationsState[camelCase(`set_${key}`)](false);
      }
    }
  }
};

export const buildUrlSearch = (token, client, organizationsState) => {
  let params = {
    access_token: token,
    client: client,
    [organizationsState.nameFlag ? "name" : "domain"]: organizationsState.name,
    limit: organizationsState.size,
    offset: organizationsState.offset,
    order: organizationsState.order,
    order_by: organizationsState.orderedBy,
  };

  for (const [key, filters] of Object.entries(filtersConfiguration)) {
    if (organizationsState[key]) {
      for (const filter of filters) {
        switch (filter.type) {
          case "date":
            if (
              organizationsState[camelCase(filter.state)].format("YYYY-MM") !==
              dayjs().add(1, "months").format("YYYY-MM")
            ) {
              params = assignIn(params, {
                [filter.param]:
                  organizationsState[camelCase(filter.state)].valueOf(),
              });
            }
            break;
          case "array":
            params = assignIn(params, {
              [filter.param]: Array.from(
                organizationsState[camelCase(filter.state)]
              ).join(","),
            });
            break;
          default:
            params = assignIn(params, {
              [filter.param]: organizationsState[camelCase(filter.state)],
            });
        }
      }
    }
  }

  params = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => value !== "")
  );
  return params;
};
