import clsx from "clsx";
import { compactInteger, oxford } from "humanize-plus";
import { camelCase } from "lodash";
import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";

import {
  classifications,
  trafficClassifications,
  decisions,
  investmentTypesFilter,
  investmentAmountDaysZones,
} from "../../../../../const";
import sources from "../../../../../components/js/content/sources";
import stages from "../../../../../components/js/config/streak_stages";
import users from "../../../../../components/js/config/inreach_team";
import config from "../../../../../config";

import "../../scss/FilterStatus.scoped.scss";

function FilterStatusCloseButton(props) {
  const { filter, noInteractive, organizationsState } = props;

  return noInteractive ? null : (
    <button
      class="d-inline-block btn btn-sm btn-outline-secondary filter-end"
      onClick={(event) => {
        organizationsState.setCurrentWidget("");
        organizationsState.setOrganizations(null);
        organizationsState.setOffset(0);
        organizationsState[camelCase("set_" + filter)](false);
      }}
    >
      <i class="bi bi-x"></i>
    </button>
  );
}

function FilterStatus(props) {
  const { filter, label, noInteractive, organizationsState, userState } = props;

  const [
    loadingOrganizationsSimilarToName,
    setLoadingOrganizationsSimilarToName,
  ] = useState(false);

  useEffect(() => {
    const collectOrganizationsSimilarToName = async (id) => {
      const urlParams = new URLSearchParams({
        access_token: userState.token,
        client: userState.client,
      });

      const response = await fetch(
        `${config.api.host}/organizations/${id}?${urlParams.toString()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const json = await response.json();
      organizationsState.setOrganizationsSimilarToName(json.name);
    };

    if (organizationsState.organizationsSimilarToValue) {
      setLoadingOrganizationsSimilarToName(true);
      collectOrganizationsSimilarToName(
        organizationsState.organizationsSimilarToValue
      );
      setLoadingOrganizationsSimilarToName(false);
    }
  }, []);

  switch (filter) {
    case "classification":
    case "classificationNot":
      const listName = {
        classification: "classifications",
        classificationNot: "classificationsNot",
      };
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {oxford(
              Array.from(organizationsState[listName[filter]]).map(
                (classification) => classifications[classification]
              )
            )}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "foundedOn":
    case "createdAt":
    case "enteredStage":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status d-flex flew-rows filter-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp; &gt;{" "}
            {organizationsState[camelCase(filter + "_G_T")].format("MM-YYYY")}{" "}
            and &lt;{" "}
            {organizationsState[camelCase(filter + "_L_T")].format("MM-YYYY")}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
            noInteractive={noInteractive}
          />
        </div>
      );
    case "keywords":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status d-flex flew-rows filter-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {organizationsState.termsOperator.toUpperCase()}
            &nbsp;-&nbsp;
            {oxford(Array.from(organizationsState.terms))}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "investmentAmount":
      const investmentAmountDaysZoneLabel = (
        investmentAmountDaysZones.find(
          (zone) =>
            zone.gt === organizationsState.investmentAmountDaysGT &&
            zone.lt === organizationsState.investmentAmountDaysLT
        ) || investmentAmountDaysZones[0]
      ).label;

      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status d-flex flew-rows filter-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp; &gt;{" "}
            {compactInteger(organizationsState.investmentAmountGT)} and &lt;{" "}
            {compactInteger(organizationsState.investmentAmountLT)}{" "}
            {investmentAmountDaysZoneLabel !== "Always" &&
              investmentAmountDaysZoneLabel}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "investmentType":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status d-flex flew-rows filter-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {oxford(
              Array.from(organizationsState.investmentTypes).map(
                (investmentType) => investmentTypesFilter[investmentType]
              )
            )}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "trafficClassification":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {oxford(
              Array.from(organizationsState.trafficClassifications).map(
                (classification) => trafficClassifications[classification]
              )
            )}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "hasPredictions":
    case "hasMissingData":
    case "isClosed":
    case "isComplete":
    case "isInInbox":
    case "isSpam":
    case "isZombie":
    case "requiresMoreInfo":
    case "requiresManualHelp":
    case "makeContactRequiresAction":
    case "unableToContactRequiresAction":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {organizationsState[camelCase(filter + "_value")] && "Included"}
            {!organizationsState[camelCase(filter + "_value")] && "Excluded"}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "source":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {organizationsState.sourceValue
              ? sources.find(
                  (source) => source.value === organizationsState.sourceValue
                ).label
              : "All"}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "stage":
      const stagesSelected = organizationsState.stageValue.split(",");
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {organizationsState.stageValue && stagesSelected.length === 1
              ? stages.find(
                  (stage) => stage.key === organizationsState.stageValue
                ).name
              : stagesSelected.length > 1
              ? "Some"
              : "All"}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "location":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {organizationsState.locationContinent
              ? organizationsState.locationContinent
              : "All"}
            {organizationsState.locationCountry
              ? `-${organizationsState.locationCountry}`
              : ""}
            {organizationsState.locationCity
              ? `-${organizationsState.locationCity}`
              : ""}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "assignedTo":
      const currentAssignedTo = !organizationsState.assignedToValue
        ? "All"
        : users.find(
            (user) => organizationsState.assignedToValue === user.email
          )?.name;
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {filter === "assignedTo" && `${currentAssignedTo}`}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "statePartner":
    case "stateUser":
      const currentUserName = !organizationsState.stateUserUser
        ? "All"
        : users.find((user) => organizationsState.stateUserUser === user.email)
            ?.name;
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp;
            {filter === "stateUser" && `${currentUserName}-`}
            {decisions[organizationsState[camelCase(filter + "_value")]]}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "daysTopTech":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp; &gt; {organizationsState.daysTopTechValue}{" "}
            Days
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "previousExits":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>&nbsp; &gt; {organizationsState.previousExitsValue}{" "}
            {organizationsState.previousExitsValue > 1 ? "People" : "People"}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
    case "organizationsSimilarTo":
      return (
        <div class="my-1 me-1 d-inline-flex">
          <button
            type="button"
            className={clsx(
              "btn btn-secondary btn-sm filter-status filter-start text-start text-start",
              organizationsState.currentWidget === filter && "disabled"
            )}
            onClick={(event) => {
              organizationsState.setCurrentWidget(filter);
            }}
          >
            <b>{label}:</b>{" "}
            {loadingOrganizationsSimilarToName ? (
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              organizationsState.organizationsSimilarToName
            )}
          </button>
          <FilterStatusCloseButton
            filter={filter}
            organizationsState={organizationsState}
          />
        </div>
      );
  }
}

export default observer(FilterStatus);
