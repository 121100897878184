import React from "react";

import { investmentTypesGreen } from "../../../../../const";

export default function OrganizationInvestmentType(props) {
  const { investmentType } = props;

  const classBuilder = (type) => {
    let classes = "badge me-1 fw-normal";
    if (investmentTypesGreen.includes(type)) {
      classes += " bg-success";
    } else {
      classes += " bg-danger";
    }
    return classes;
  };

  return <span className={classBuilder(investmentType)}>{investmentType}</span>;
}
